import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './ProgramList.module.scss';
import { isMobile } from 'react-device-detect';
import rightArrow from '../../assets/rightArrow.png';
import category1 from '../../assets/category1.png';
import category2 from '../../assets/category2.png';

function ProgramList() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className={styles.categorySelectWrap}>
        <h3>진료과목</h3>
        <ul
          className={`${styles.selectProgramListWrap} ${
            isMobile ? styles.isMobile : ''
          }`}
        >
          <li
            onClick={() => {
              navigate('/hospital-list/1');
            }}
          >
            <div className={styles.programLeft}>
              <img src={category1} alt="dietLogo" />
              <div className={styles.programInfo}>
                <p>다이어트</p>
              </div>
            </div>
            <img className={styles.directImg} src={rightArrow} alt="direct" />
          </li>
          <li
            onClick={() => {
              alert('준비중입니다.');
            }}
          >
            <div className={styles.programLeft}>
              <img src={category2} alt="dietLogo" />
              <div className={styles.programInfo}>
                <p>디톡스</p>
              </div>
            </div>
            <img className={styles.directImg} src={rightArrow} alt="direct" />
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default ProgramList;
