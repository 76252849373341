import styles from './Header.module.scss';
import logo from '../../assets/logo.png';
import search from '../../assets/search.png';
import hamburger from '../../assets/header/hamburger.png';
import basket from '../../assets/header/basket.png';
import eventDirect from '../../assets/header/eventDirect.png';
import mPhotoReviewEventHeader from '../../assets/header/mPhotoReviewEventHeader.jpg';
import photoReviewEventHeader from '../../assets/header/photoReviewEventHeader.jpg';
import eventHeaderBox from '../../assets/header/eventHeaderBox.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import SearchModal from '../SearchModal/SearchModal';
import { isDesktop } from 'react-device-detect';
import { loginCheck, logout } from '../../auth/auth';
import KakaoBanner from '../../components/KakaoBanner/KakaoBanner';
import { loginConfirm, loginConfirmRurl } from '../../common/loginConfirm';
import SideMenuMobile from '../../mobileComponents/SideMenuMobile/SideMenuMobile';
import axiosClient from '../../libs/axiosClient';
import MyPage from '../../pages/MyPage/MyPage';
interface IPropsType {
  setIsSmallType?: React.Dispatch<React.SetStateAction<boolean>>;
  basketChange?: any;
  relation?: any;
  adBanner?: any;
  naviBarRef?: any;
}
function Header(props: IPropsType) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSmallType, setIsSmallType] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [myPageInfo, setMyPageInfo] = useState<any>(0);
  useEffect(() => {
    if (!loginCheck()) return;
    axiosClient
      .get('/data/mypage')
      .then((res) => {
        setMyPageInfo(res.data.data.cart_count);
      })
      .catch((err) => console.log(err));
  }, [props.basketChange]);
  const movingBarRef = useRef<HTMLDivElement>(null);
  function handleNavigationBarMove(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.left = e.currentTarget.offsetLeft + 'px';
    movingBarRef.current.style.width = e.currentTarget.offsetWidth + 'px';
  }
  function handleNavigationBarHide(
    e: React.MouseEvent<HTMLUListElement, MouseEvent>,
  ) {
    if (!movingBarRef.current) return;
    movingBarRef.current.style.width = '0px';
  }
  function handleLogout() {
    logout();
    window.location.href = '/';
  }
  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY > 50) {
          setIsSmallType(true);
          if (props.setIsSmallType) props.setIsSmallType(true);
        } else {
          setIsSmallType(false);
          if (props.setIsSmallType) props.setIsSmallType(false);
        }
      }, 200),
    [],
  );
  useEffect(() => {
    if (!isDesktop) return;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    if (loginCheck()) setIsLogin(true);
    else setIsLogin(false);
  }, []);
  const [mobileSideOpen, setMobileSideOpen] = useState(false);
  const [hitNavigation, setHitNavigation] = useState(0);

  const naviBarRef = useRef(props.naviBarRef);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    naviBarRef.current = props.naviBarRef;
  }, [props.naviBarRef]);

  function onScroll() {
    setPosition(window.scrollY);
    // if (window.scrollY > props.naviBarRef)
    if (window.scrollY > naviBarRef.current - 50) {
      setHitNavigation(1);
    } else {
      setHitNavigation(0);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      {isDesktop ? (
        <>
          <div
            className={`${styles.heighter}`}
            style={{ height: location.pathname !== '/' ? '120px' : '70px' }}
          ></div>
          <div
            className={`${styles.container} ${
              isSmallType
                ? location.pathname !== '/' && !props.relation
                  ? styles.tiny
                  : props.relation
                  ? styles.relationTiny
                  : styles.mainTiny
                : props.relation
                ? styles.relationTiny
                : ''
            } container`}
          >
            {location.pathname !== '/' && !props.relation && (
              <div
                className={styles.eventHeader}
                onClick={() => {
                  navigate('/event/28');
                }}
              >
                <img
                  style={{
                    width: '100%',
                  }}
                  src={photoReviewEventHeader}
                />
              </div>
            )}
            <div className={styles.header}>
              <div className={styles.logoWrap}>
                <img
                  src={logo}
                  alt="logo"
                  className={styles.logo}
                  onClick={() => navigate('/')}
                />
              </div>
              <ul
                className={styles.menus}
                onMouseLeave={(e) => handleNavigationBarHide(e)}
              >
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate(`/companyInfo`)}
                >
                  <span>커넥트닥터</span>
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate(`/program-list`)}
                >
                  <span>병원진료</span>
                </li>
                <li
                  className={styles.productMenu}
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => navigate('/productList')}
                >
                  <span>상품</span>
                  {/* <ul>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/productList');
                      }}
                    >
                      전체상품
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/productList?cuid=487');
                      }}
                    >
                      처방의약품
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/productList?cuid=488');
                      }}
                    >
                      건강식품
                    </li>
                  </ul> */}
                </li>
                <li
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!loginConfirmRurl('/myWeight')) return;
                    navigate('/myWeight');
                  }}
                >
                  <span>체중기록</span>
                </li>
                <li
                  className={styles.communityMenu}
                  onMouseEnter={(e) => handleNavigationBarMove(e)}
                  onClick={() => {
                    if (!isLogin) navigate('/event');
                    else navigate('/review/all');
                  }}
                >
                  <span>커뮤니티</span>
                  <ul>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/notice');
                      }}
                    >
                      공지사항
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/event');
                      }}
                    >
                      이벤트
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!loginConfirmRurl('/review/all')) return;
                        navigate('/review/all');
                      }}
                    >
                      상품 후기
                    </li>
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate('/faq');
                      }}
                    >
                      FAQ
                    </li>
                  </ul>
                </li>
                <div ref={movingBarRef} className={styles.movingBar}></div>
              </ul>

              <ul className={styles.btns}>
                <li>
                  {!isLogin ? (
                    <Link to="/join">회원가입</Link>
                  ) : (
                    <Link to="/myPage">마이페이지</Link>
                  )}
                </li>
                <li>
                  {!isLogin && location.pathname !== '/login' ? (
                    <Link
                      to={`/login?rurl=${location.pathname + location.search}`}
                    >
                      로그인
                    </Link>
                  ) : !isLogin && location.pathname === '/login' ? (
                    <Link to={`${location.pathname + location.search}`}>
                      로그인
                    </Link>
                  ) : (
                    <a href="#none" onClick={handleLogout}>
                      로그아웃
                    </a>
                  )}
                </li>
                <li>
                  <Link style={{ padding: '4px 0 4px 4px' }} to="/basket">
                    장바구니
                  </Link>
                  <Link style={{ color: 'var(--theme-color)' }} to="/basket">
                    ({myPageInfo && myPageInfo})
                  </Link>
                </li>
              </ul>
            </div>
            {/* <KakaoBanner /> */}
          </div>
          {searchOpen && <SearchModal setOpen={setSearchOpen} />}
        </>
      ) : (
        <>
          {location.pathname !== '/' && !props.relation && (
            <div
              className={styles.mEventHeader}
              onClick={() => {
                navigate('/event/28');
              }}
            >
              <div className={styles.mEventBox}>
                <img src={eventHeaderBox} />
                <span>포토리뷰 </span>
                <span style={{ fontWeight: '300' }}>쓰고 </span>
                <span>5천원 적립금 </span>
                <span style={{ fontWeight: '300' }}>받자!</span>
              </div>
              <div className={styles.mEventDirect}>
                <img src={eventDirect} />
              </div>
            </div>
          )}
          <div
            className={
              // hitNavigation == 0
              //   ? location.pathname !== '/'
              //     ? styles.mContainer
              //     : props.relation
              //     ? styles.mRelation
              //     : styles.mContainerY
              //   : ''
              hitNavigation === 0
                ? location.pathname !== '/' && !props.relation
                  ? styles.mContainer
                  : props.relation
                  ? styles.mRelation
                  : styles.mContainerY
                : props.relation
                ? styles.mRelationHit
                : styles.mContainerY

              // hitNavigation == 0
              // ? location.pathname !== '/'
              //   ? styles.mContainer
              //   : styles.mContainerY
              // : styles.mContainerY
            }
          >
            <img
              src={logo}
              alt="logo"
              className={styles.logo}
              onClick={() => navigate('/')}
            />
            <div className={styles.btns}>
              {/* <img src={search} alt="search" className={styles.search} /> */}
              <img
                src={basket}
                alt="basket"
                className={styles.basket}
                onClick={() => navigate('/basket')}
              />
              {myPageInfo !== 0 && (
                <div
                  className={styles.basketCntWrap}
                  onClick={() => navigate('/basket')}
                >
                  {myPageInfo && myPageInfo}
                </div>
              )}
              <img
                src={hamburger}
                alt="hamburger"
                className={styles.hamburger}
                onClick={() => setMobileSideOpen(true)}
              />
            </div>
          </div>
          <div
            className={`${styles.mHeighter} ${
              location.pathname === '/' ? styles.mainHeight : ''
            }`}
          ></div>
          {mobileSideOpen && <SideMenuMobile isOpen={setMobileSideOpen} />}
        </>
      )}
    </>
  );
}
export default Header;
