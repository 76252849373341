import styles from './AddressEdit.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { isDesktop, isMobile } from 'react-device-detect';
import addr from '../../assets/question/addr.png';
import orderer from '../../assets/question/orderer.png';
import closeBtn from '../../assets/closeBtn03.png';
import countryDrop from '../../assets/countryDrop.png';
import mPrev from '../../assets/question/mPrev.png';
import { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ITokenInfoType, logout } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import axiosClient from '../../libs/axiosClient';
import prevNavi from '../../assets/prevNavi.png';

declare var daum: any;

export interface IOrdererType {
  country_name: string;
  is_oversea: string;
  order_id: string;
  receive_address: string;
  receive_address1: string;
  receive_address2: string;
  receive_name: string;
  receive_phone: string;
  receive_zipcode: string;
  request_memo: string;
  sns_id: string;
  sns_type: string;
}
function AddressEdit() {
  const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z]+$/;
  const globalNameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
  const payappTelRegex = /^01[0-9]*$/;
  const telRegex = /^[0-9]*$/;
  const navigate = useNavigate();
  const { orderId } = useParams();
  const countryList = ['미국', '중국', '일본', '캐나다', '호주', '기타'];
  const [ordererData, setOrdererData] = useState<IOrdererType>();
  const [countryOpen, setCountryOpen] = useState<boolean>(false);
  const [openPostcode, setOpenPostcode] = useState<boolean>(false);
  const [deliveryNation, setDeliveryNation] =
    useState('배송받을 국가를 선택해주세요');
  const [snsIdMethod, setSnsIdMethod] = useState('kakaotalk');
  const [deliverySnsId, setDeliverySnsId] = useState('');
  const [deliveryEtcNation, setDeliveryEtcNation] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const [deliveryName, setDeliveryName] = useState('');
  const [deliveryTel, setDeliveryTel] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [deliveryAddressDetail, setDeliveryAddressDetail] = useState('');
  const [globalDeliveryName, setGlobalDeliveryName] = useState('');
  const [globalDeliveryTel, setGlobalDeliveryTel] = useState('');
  const [globalZipCode, setGlobalZipCode] = useState('');
  const [globalDeliveryAddress, setGlobalDeliveryAddress] = useState('');
  const [globalDeliveryAddressDetail, setGlobalDeliveryAddressDetail] =
    useState('');
  const [deliveryMemo, setDeliveryMemo] = useState('');
  const [validation, setValidation] = useState({
    deliveryNation: false,
    deliveryEtcNation: false,
    deliveryName: false,
    deliveryTel: false,
    zipCode: false,
    deliveryAddress: false,
    deliveryAddressDetail: false,
    globalDeliveryName: false,
    globalDeliveryTel: false,
    snsIdMethod: false,
    deliverySnsId: false,
    globalZipCode: false,
    globalDeliveryAddress: false,
    globalDeliveryAddressDetail: false,
  });

  useEffect(() => {
    axiosClient
      .get(`/data/order/delivery?order_id=${orderId}`)
      .then((res) => {
        setOrdererData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!ordererData) return;

    if (ordererData.country_name === '한국') {
      setDeliveryMethod(0);
      setDeliveryName(ordererData.receive_name);
      setDeliveryTel(ordererData.receive_phone);
      setZipCode(ordererData.receive_zipcode);
      setDeliveryAddress(ordererData.receive_address1);
      setDeliveryAddressDetail(ordererData.receive_address2 || '');
      setDeliveryMemo(ordererData.request_memo);
    } else {
      setDeliveryMethod(1);
      setDeliveryNation(ordererData.country_name);
      setGlobalDeliveryName(ordererData.receive_name);
      setGlobalDeliveryTel(ordererData.receive_phone);
      setSnsIdMethod(ordererData.sns_type === 'kakao' ? 'kakaotalk' : 'line');
      setDeliverySnsId(ordererData.sns_id);
      setGlobalZipCode(ordererData.receive_zipcode);
      setGlobalDeliveryAddress(ordererData.receive_address1);
      setGlobalDeliveryAddressDetail(ordererData.receive_address2 || '');
      setDeliveryMemo(ordererData.request_memo);
    }
  }, [ordererData]);

  const handle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode((current) => !current);
    },

    // 주소 선택 이벤트
    selectAddress: (data: any) => {
      setZipCode(data.zonecode);
      setDeliveryAddress(data.address);
      setOpenPostcode(false);
    },
  };

  const handleSearchAddressOpen = () => {
    if (!isDesktop) setOpenPostcode(true);
    else {
      new daum.Postcode({
        oncomplete: function (data: any) {
          setZipCode(data.zonecode);
          setDeliveryAddress(data.address);
        },
      }).open({ popupKey: 'pcPostPopup1' });
    }
  };

  const handleCheck = () => {
    let validate = {
      deliveryNation: false,
      deliveryEtcNation: false,
      deliveryName: false,
      deliveryTel: false,
      zipCode: false,
      deliveryAddress: false,
      deliveryAddressDetail: false,
      globalDeliveryName: false,
      globalDeliveryTel: false,
      snsIdMethod: false,
      deliverySnsId: false,
      globalZipCode: false,
      globalDeliveryAddress: false,
      globalDeliveryAddressDetail: false,
    };

    if (deliveryMethod === 0) {
      if (!nameRegex.test(deliveryName)) validate.deliveryName = true;
      if (!telRegex.test(deliveryTel) || deliveryTel.length < 8)
        validate.deliveryTel = true;
      if (!deliveryAddress.length) validate.deliveryAddress = true;
      if (!zipCode.length) validate.zipCode = true;
    }
    if (deliveryMethod === 1) {
      if (!deliveryNation.length) validate.deliveryNation = true;
      if (deliveryNation === '배송받을 국가를 선택해주세요')
        validate.deliveryNation = true;
      if (deliveryNation === '기타') {
        if (!deliveryEtcNation.length) validate.deliveryEtcNation = true;
      }
      if (!globalNameRegex.test(globalDeliveryName))
        validate.deliveryName = true;
      if (!globalDeliveryTel.length) validate.deliveryTel = true;
      if (!deliverySnsId.length) validate.deliverySnsId = true;
      if (!globalZipCode.length) validate.zipCode = true;
      if (!globalDeliveryAddress.length) validate.deliveryAddress = true;
      if (!globalDeliveryAddressDetail.length)
        validate.deliveryAddressDetail = true;
    }

    setValidation({ ...validate });

    if (Object.values(validate).includes(true)) {
      alert('필수 입력 정보를 올바르게 작성해주세요.');
      return false;
    } else return true;
  };

  const onSubmit = () => {
    if (!orderId) return;
    const isOk = handleCheck();
    if (!isOk) return;

    let formData = new FormData();
    formData.append('order_id', orderId);
    formData.append(
      'receive_name',
      deliveryMethod === 0 ? deliveryName : globalDeliveryName,
    );
    formData.append(
      'receive_phone',
      deliveryMethod === 0 ? deliveryTel : globalDeliveryTel,
    );
    formData.append(
      'receive_zipcode',
      deliveryMethod === 0 ? zipCode : globalZipCode,
    );
    formData.append(
      'receive_address1',
      deliveryMethod === 0 ? deliveryAddress : globalDeliveryAddress,
    );
    formData.append(
      'receive_address2',
      deliveryMethod === 0
        ? deliveryAddressDetail
        : globalDeliveryAddressDetail,
    );
    formData.append('request_memo', deliveryMemo);
    if (deliveryMethod === 1) {
      formData.append('is_oversea', 'Y');
      formData.append(
        'country_name',
        deliveryNation === '기타' ? deliveryEtcNation : deliveryNation,
      );
      formData.append(
        'sns_type',
        snsIdMethod === 'kakaotalk' ? 'kakao' : 'line',
      );
      formData.append('sns_id', deliverySnsId);
    }

    axiosClient
      .post('/func/order/delivery', formData)
      .then((res) => {
        alert('배송지가 정상적으로 수정되었습니다.');
        return navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`${styles.container} ${!isDesktop ? styles.isMobile : ''}`}>
      <Header />
      <div className={styles.contentWrap}>
        <div className={styles.header}>
          {isMobile && (
            <img onClick={() => navigate(-1)} src={prevNavi} alt="prev" />
          )}
          <span>배송정보 변경</span>
          {isMobile && <div></div>}
        </div>
        <div
          className={`${styles.mainWrap} ${
            deliveryMethod === 1 ? styles.globalMainWrap : ''
          }`}
        >
          <div className={styles.addrForm}>
            {deliveryMethod === 1 && (
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  <span>배송 국가</span>
                </div>
                <div className={styles.countryRightWrap}>
                  <div
                    style={{
                      border: countryOpen ? '1px solid #d9d9d9' : '',
                      borderRadius: countryOpen ? '5px 5px 0px 0px' : '',
                      borderBottom: countryOpen ? 'none' : '',
                    }}
                    className={`${styles.countrySelectWrap} ${
                      validation.deliveryNation ? styles.validationError : ''
                    }`}
                    onClick={() => {
                      setCountryOpen((prev) => !prev);
                    }}
                  >
                    <p
                      style={{
                        color:
                          deliveryNation !== '배송받을 국가를 선택해주세요'
                            ? '#131C31'
                            : '',
                      }}
                    >
                      {deliveryNation}
                    </p>
                    <img
                      src={countryDrop}
                      alt="countryDrop"
                      style={{
                        transform: countryOpen ? 'rotate(180deg)' : 'none',
                      }}
                    />
                  </div>
                  {countryOpen && (
                    <div className={styles.countryListWrap}>
                      {countryList.map((item) => (
                        <div
                          key={item}
                          onClick={() => {
                            setCountryOpen((prev) => !prev);
                            setDeliveryNation(item);
                            if (item === '기타') setDeliveryEtcNation('');
                          }}
                        >
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  )}
                  {deliveryNation === '기타' && (
                    <div
                      style={{
                        marginTop: '8px',
                      }}
                    >
                      <label className={styles.label}>
                        <div
                          className={`${styles.inputWrap} ${
                            validation.deliveryEtcNation
                              ? styles.validationError
                              : ''
                          }`}
                        >
                          <input
                            type="text"
                            placeholder="ex) 대한민국"
                            className={styles.normalInput}
                            value={deliveryEtcNation}
                            onChange={(e) => {
                              setDeliveryEtcNation(e.currentTarget.value);
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.labelWrap}>
              <div className={styles.labelTitleWrap}>
                <span>*</span>
                {deliveryMethod === 0 ? (
                  <span>수령인</span>
                ) : (
                  <span>수령인(영문)</span>
                )}
              </div>
              <div
                className={`${styles.inputWrap} ${
                  validation.deliveryName ? styles.validationError : ''
                }`}
              >
                <input
                  type="text"
                  placeholder="이름"
                  className={styles.normalInput}
                  value={
                    deliveryMethod === 0 ? deliveryName : globalDeliveryName
                  }
                  onChange={(e) => {
                    if (deliveryMethod === 0)
                      setDeliveryName(e.currentTarget.value);
                    else setGlobalDeliveryName(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={styles.labelWrap}>
              <div className={styles.labelTitleWrap}>
                <span>*</span>
                <span>연락처</span>
              </div>
              <div
                className={`${styles.inputWrap} ${
                  validation.deliveryTel ? styles.validationError : ''
                }`}
              >
                {deliveryMethod === 0 ? (
                  <input
                    type="text"
                    placeholder="01000000000"
                    className={styles.normalInput}
                    value={deliveryTel}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      // if (isNaN(Number(value)) && value !== '+') return;
                      setDeliveryTel(value);
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="연락처를 입력해주세요"
                    className={styles.normalInput}
                    value={globalDeliveryTel}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setGlobalDeliveryTel(value);
                    }}
                  />
                )}
              </div>
            </div>
            {deliveryMethod === 1 && (
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  <span>아이디</span>
                </div>
                <div className={styles.customCheckBoxListWrap}>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      snsIdMethod === 'kakaotalk' ? styles.hit : ''
                    }`}
                    onClick={() => {
                      setSnsIdMethod('kakaotalk');
                      if (snsIdMethod !== 'kakaotalk') setDeliverySnsId('');
                    }}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>KakaoTalk ID</span>
                  </div>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      snsIdMethod === 'line' ? styles.hit : ''
                    }`}
                    onClick={() => {
                      setSnsIdMethod('line');
                      if (snsIdMethod !== 'line') setDeliverySnsId('');
                    }}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>Line ID</span>
                  </div>
                </div>
              </div>
            )}
            {deliveryMethod === 1 && (
              <label
                style={{
                  marginTop: '-8px',
                }}
                className={styles.labelWrap}
              >
                <div
                  className={`${styles.inputWrap} ${
                    validation.deliverySnsId ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="카카오톡 또는 라인아이디"
                    className={styles.normalInput}
                    value={deliverySnsId}
                    onChange={(e) => setDeliverySnsId(e.currentTarget.value)}
                  />
                </div>
                <p className={styles.globalTelAlert}>
                  * 해외 체류로 인해 카카오톡/라인 ID를 입력하신 경우,
                  보이스톡으로 진료를 진행합니다. 반드시{' '}
                  <span className={styles.hit}>아이디 검색 허용</span>을 미리
                  설정해 주세요.
                </p>
              </label>
            )}
            <div className={`${styles.labelWrap} ${styles.addrLabelWrap}`}>
              <div className={styles.labelTitleWrap}>
                <span>*</span>
                {deliveryMethod === 0 ? (
                  <span>배송지 주소</span>
                ) : (
                  <span>배송지 주소(영문)</span>
                )}
              </div>
              <div
                className={`${styles.inputWrap} ${
                  validation.zipCode ? styles.validationError : ''
                }`}
              >
                {deliveryMethod === 0 ? (
                  <input
                    style={{
                      borderRadius: '5px 0 0 5px',
                    }}
                    type="text"
                    placeholder="우편번호"
                    value={zipCode}
                    readOnly
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="우편번호"
                    value={globalZipCode}
                    onChange={(e) => setGlobalZipCode(e.currentTarget.value)}
                  />
                )}
                {deliveryMethod === 0 && (
                  <div
                    onClick={handleSearchAddressOpen}
                    className={styles.addressSearch}
                  >
                    검색
                  </div>
                )}
                {openPostcode && (
                  <div className={styles.addressModalWrap}>
                    <div className={styles.addressModal}>
                      <div className={styles.modalHeader}>
                        <img
                          src={closeBtn}
                          alt="closeBtn"
                          onClick={() => setOpenPostcode(false)}
                        />
                      </div>
                      <DaumPostcode
                        onComplete={handle.selectAddress}
                        autoClose={false}
                      />
                    </div>
                  </div>
                )}
              </div>
              {deliveryMethod === 1 && (
                <div className={styles.addrText}>ex) 100102</div>
              )}
              <div
                className={`${styles.inputWrap} ${
                  validation.deliveryAddress ? styles.validationError : ''
                }`}
              >
                {deliveryMethod === 0 ? (
                  <input
                    type="text"
                    placeholder="주소"
                    className={styles.normalInput}
                    value={deliveryAddress}
                    readOnly
                  />
                ) : (
                  <input
                    type="text"
                    placeholder="주소"
                    className={styles.normalInput}
                    value={globalDeliveryAddress}
                    onChange={(e) =>
                      setGlobalDeliveryAddress(e.currentTarget.value)
                    }
                  />
                )}
              </div>
              {deliveryMethod === 1 && (
                <div className={styles.addrText}>ex) 123 Main Street</div>
              )}
              <div
                className={`${styles.inputWrap} ${
                  validation.deliveryAddressDetail ? styles.validationError : ''
                }`}
              >
                <input
                  type="text"
                  placeholder="상세주소"
                  className={styles.normalInput}
                  value={
                    deliveryMethod === 0
                      ? deliveryAddressDetail
                      : globalDeliveryAddressDetail
                  }
                  onChange={(e) => {
                    if (deliveryMethod === 0)
                      setDeliveryAddressDetail(e.currentTarget.value);
                    else setGlobalDeliveryAddressDetail(e.currentTarget.value);
                  }}
                />
              </div>
              {deliveryMethod === 1 && (
                <div className={`${styles.addrText} ${styles.lastAddrText}`}>
                  ex) Anytown, CA 12345, USA
                </div>
              )}
            </div>
            <div className={styles.labelWrap}>
              <div className={styles.labelTitleWrap}>
                <span></span>
                <span>배송메세지</span>
              </div>
              <div className={styles.inputWrap}>
                <input
                  type="text"
                  placeholder="메시지를 입력해주세요"
                  className={styles.normalInput}
                  value={deliveryMemo}
                  onChange={(e) => setDeliveryMemo(e.currentTarget.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${styles.btnWrap} ${
            deliveryMethod === 1 ? styles.globalBtnWrap : ''
          }`}
        >
          <div className={styles.orderBtnWrap} onClick={onSubmit}>
            <span>변경하기</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressEdit;
