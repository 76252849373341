import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import FaqFrame from '../../components/FaqFrame/FaqFrame';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import axiosClient from '../../libs/axiosClient';
import styles from './Faq.module.scss';

export interface IFaqType {
  content: string;
  created_at: string;
  id: number;
  title: string;
  type: string;
}
function Faq() {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categoryList, setCategoryList] = useState<object>({});
  const [faqList, setFaqList] = useState<IFaqType[]>([]);
  const { data: faqListQuery } = useQuery<{
    data: IFaqType[];
    faq_type: {
      [key: number]: string;
    };
  }>('faq', () => axiosClient.get('/data/faq').then((res) => res.data.data), {
    staleTime: 60000 * 60 * 24, //1일 캐싱
  });
  useEffect(() => {
    if (!faqListQuery) return;
    setFaqList(faqListQuery.data);
    setCategoryList(faqListQuery.faq_type);
  }, [faqListQuery]);
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.top}>
        <h3 className={styles.title}>FAQ</h3>
        <div className={styles.serviceInfo}>
          <span>커넥트닥터 고객센터</span>
          <h4>070-4138-0176</h4>
          <span>1:1 상담톡을 이용해주시면 더욱 빠른 상담이 가능합니다.</span>
          <div className={styles.serviceTimeInfo}>
            <span>am 10:00 - pm 18:00</span>
            <span>lunch am 11:30 - pm 12:30</span>
            <span>토요일, 공휴일 휴무</span>
          </div>
        </div>
      </div>
      <div className={styles.categoryListWrap}>
        <h4>자주 묻는 질문</h4>
        <ul className={styles.categoryList}>
          <li
            onClick={() => setSelectedCategory(0)}
            className={selectedCategory == 0 ? styles.hit : ''}
          >
            전체
          </li>
          {Object.values(categoryList).map((category: string, idx: number) => (
            <li
              key={idx}
              onClick={() => setSelectedCategory(idx + 1)}
              className={selectedCategory == idx + 1 ? styles.hit : ''}
            >
              {category}
            </li>
          ))}
        </ul>
      </div>
      <ul className={styles.faqList}>
        {faqList &&
          faqList
            .filter((faq) => {
              if (selectedCategory == 0) return faq;
              return Number(faq.type) == selectedCategory;
            })
            .map((faq) => (
              <li key={faq.id}>
                <FaqFrame faq={faq} />
              </li>
            ))}
        {faqList &&
          !faqList.filter((faq) => {
            if (selectedCategory == 0) return faq;
            return Number(faq.type) == selectedCategory;
          }).length && (
            <p className={styles.noData}>등록된 질문이 존재하지 않습니다.</p>
          )}
      </ul>
      <Footer />
    </div>
  );
}
export default Faq;
