import Header from '../../components/Header/Header';
import styles from './JoinSuccess.module.scss';
import joinSuccess from '../../assets/joinSuccess.png';
import { useNavigate } from 'react-router-dom';
function JoinSuccess() {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div className={styles.content}>
        <img src={joinSuccess} alt="joinSuccess" />
        <h2>회원가입 완료!</h2>
        <span>커넥트닥터 회원이 되신 것을 환영해요.</span>
        <p>
          지금 바로 <span>커넥트닥터의 비대면 서비스</span>를<br />
          편리하게 이용해보세요!
        </p>
        <button onClick={() => navigate('/')}>홈으로</button>
      </div>
    </div>
  );
}
export default JoinSuccess;
