import { useState, useEffect } from 'react';
import styles from './ChallengeSubmitModal.module.scss';
import challengeSubmitImg1 from '../../assets/challengeSubmitImg1.png';
import challengeSubmitImg2 from '../../assets/challengeSubmitImg2.png';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../libs/axiosClient';

interface IPropsType {
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}

function ChallengeSubmitModal(props: IPropsType) {
  const navigate = useNavigate();
  const [challengeParticipation, setChallengeParticipation] =
    useState<String>();
  const [challengeNextStartDay, setChallengeNextStartDay] = useState<String>();
  const [challengeNextEndDay, setChallengeNextEndDay] = useState<String>();
  let startDay;
  let endDay;

  useEffect(() => {
    // 몸무게 참여여부 조회
    axiosClient
      .get(`/data/challenge/my/1/is_participated`)
      .then(function (res) {
        setChallengeParticipation(res.data.data.is_participated);
        startDay = new Date(res.data.data.next_start_date);
        endDay = new Date(res.data.data.next_end_date);
        setChallengeNextStartDay(
          startDay.getMonth() + 1 + '월 ' + startDay.getDate() + '일',
        );
        setChallengeNextEndDay(
          endDay.getMonth() + 1 + '월 ' + endDay.getDate() + '일',
        );
      });
  }, []);

  return (
    <div className={styles.challengeSubmitModal}>
      <div className={styles.challengeSubmitWrap}>
        <div className={styles.challengeTextOk}>
          <div>
            <p>체중기록</p>
            <p>이번 주 체중이</p>
            <span>입력 완료</span>
            <span>됐습니다!</span>
          </div>
          <div>
            <span>다음 입력 기간: </span>
            <span>
              {challengeNextStartDay} ~ {challengeNextEndDay}
            </span>
          </div>
        </div>

        <div className={styles.challengePointInfo}>
          <div className={styles.challengePointText}>
            <span>리워드 1,000P</span>
            <span>가 지급됐습니다.</span>
          </div>
          <img
            className={styles.challengeSubmitImg2}
            src={challengeSubmitImg2}
            alt="challengeSubmitImg"
          />
          <div className={styles.challengePointBtnWrap}>
            <button onClickCapture={() => navigate('/myWeight/point')}>
              리워드 보러가기
            </button>
            <button
              className={styles.closeBtn}
              onClick={() => props.closer(false)}
            >
              닫기
            </button>
          </div>
        </div>
        <img
          className={styles.challengeSubmitImg1}
          src={challengeSubmitImg1}
          alt="challengeSubmitImg"
        />
      </div>
    </div>
  );
}
export default ChallengeSubmitModal;
