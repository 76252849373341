import styles from "./PayModal.module.scss";
import box from "../../assets/question/box.png";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import { useEffect } from "react";

function PayModal(props: any) {
  const navigate = useNavigate();

  return (
    <>
      {props.orderId && (
        <div
          className={`${styles.modalContainer} ${
            !isDesktop ? styles.isMobile : ""
          }`}
        >
          <div className={styles.modalWrap}>
            <div
              className={styles.closeWrap}
              onClick={() => props.closer(false)}
            >
              닫기
            </div>
            <div className={styles.textWrap}>
              <div>
                <span>잠깐!! 결제가 안 끝났어요.</span>
                <br />
                <span className={styles.hitText}>두 번째 결제</span>
                <span>를 진행해 주세요.</span>
              </div>
            </div>
            <div className={styles.borderWrap}></div>
            <div className={styles.alertWrap}>
              <span>꼭 이어서 결제해야 출고됩니다</span>
              <img src={box} alt="box" />
            </div>
            <div
              className={styles.btnWrap}
              onClick={() => {
                // navigate('/');
                if (props.orderId) navigate(`/order-continue/${props.orderId}`);
                props.closer(false);
              }}
            >
              두번째 결제하러 가기
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PayModal;
