import styles from './MainFaqFrame.module.scss';
import bottomArrow from '../../assets/bottomArrow03.png';
import { useState } from 'react';
import { IFaqType } from '../../pages/Faq/Faq';
import bottomArrow2 from '../../assets/bottomArrow04.png';

function MainFaqFrame(props: { faq: IFaqType }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={`${styles.askOutWrap} ${isOpen ? styles.isOpen : ''}`}>
        <div
          className={`${styles.askWrap} ${isOpen ? styles.isOpen : ''}`}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <span>{props.faq.title}</span>
          <img
            src={!isOpen ? bottomArrow2 : bottomArrow}
            alt="bottomArrow"
            style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
          />
        </div>
      </div>

      <div
        className={`${styles.answerWrap} ${isOpen ? styles.isOpen : ''}`}
        dangerouslySetInnerHTML={{ __html: props.faq.content }}
      ></div>
    </div>
  );
}
export default MainFaqFrame;
