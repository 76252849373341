import styles from './Join.module.scss';
import loginBack from '../../assets/loginBack.png';
import { isDesktop } from 'react-device-detect';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import kakaoLogo from '../../assets/kakaoLogo.png';
import { KAKAO_AUTH_URL } from '../../auth/Kakao';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { setToken } from '../../auth/auth';
import inputCancel from '../../assets/inputCancel.png';
import selectOpen from '../../assets/selectOpen.png';
import JoinAgreementModal from '../../components/JoinAgreementModal/JoinAgreementModal';
import { useForm } from 'react-hook-form';

export function checkTel(tel: string) {
  for (let i = 0; i < tel.length; i++) {
    if (isNaN(Number(tel[i]))) return false;
  }
  if (tel.length < 12) return false;
  else return true;
}
const idRegex = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{4,30}$/;
var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,30}$/;
const nameRegex = /^[가-힣a-zA-Z ]+$/;
const emailRegex =
  /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
const telRegex = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
function Join() {
  const navigate = useNavigate();
  const [emailDirectly, setEmailDirectly] = useState(false);
  const [refCodeUrlSate, setRefCodeUrlState] = useState<string>('');
  const [refCode, setRefCode] = useState<string>('');
  let refCodeUrl: any;
  const localKey = 'refCode';

  useEffect(() => {
    let refCodeSearch = window.location.search.slice(15);
    if (window.location.search !== '') setRefCodeUrlState(refCodeSearch);
    else if (window.location.search === '') setRefCodeUrlState('');
    refCodeUrl = refCodeSearch;
  }, []);

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) navigate(-1);
  }, []);

  useEffect(() => {
    if (refCodeUrl) {
      axiosClient
        .get(`/data/referrer/is_exist?referrer_code=${refCodeUrl}`, {
          headers: {
            'Access-Control-Allow-Origin': 'https://api.connectdoctor.co.kr',
            'ngrok-skip-browser-warning': true,
          },
        })
        .then((res) => {
          localStorage.setItem(localKey, refCodeUrl);
          const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;
          const localDataStore = {
            refCodeUrl,
            expirationTime,
          };
          localStorage.setItem(localKey, JSON.stringify(localDataStore));

          setTimeout(() => {
            localStorage.removeItem(localKey);
          }, 24 * 60 * 60 * 1000);
        });
    }
  }, []);

  function marketingAgree(idx: number) {
    if (idx == 0 || idx == 1) {
      setValue(
        'marketing',
        (() => {
          let newVal = watch().marketing;
          newVal[idx] = !newVal[idx];
          return [newVal[0], newVal[1], false];
        })(),
      );
    } else setValue('marketing', [false, false, !watch().marketing[2]]);
  }
  function handleIdCheck() {
    if (!idRegex.test(watch().id)) {
      alert('아이디는 영문, 숫자를 포함한 4자 이상 30자 이하만 가능합니다.');
      return;
    }
    axiosClient
      .get(`/data/verification/id?id=${watch().id}`)
      .then((res) => {
        setValue('idCheck', true);
        alert('사용 가능한 아이디입니다.');
      })
      .catch((err) =>
        alert(
          err.response.data.detail ||
            '이미 사용중이거나 사용할 수 없는 아이디입니다.',
        ),
      );
  }
  function handleEmailCheck() {
    if (!emailRegex.test(watch().email1 + '@' + watch().email2)) {
      alert('올바르지 않은 이메일 형식입니다.');
      return;
    }
    axiosClient
      .get(
        `/data/verification/email?email=${
          watch().email1 + '@' + watch().email2
        }`,
      )
      .then((res) => {
        setValue('emailCheck', true);
        alert('사용 가능한 이메일입니다.');
      })
      .catch((err) => alert(err.response.data.detail));
  }
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const [agreementModalType, setAgreementModalType] = useState(0);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = (data: any) => {
    let formData = new FormData();
    let referrerCode: string;
    formData.append('individual_id', data.id);
    formData.append('password', data.pw);
    formData.append('name', data.name);
    formData.append('email', data.email1 + '@' + data.email2);
    formData.append('phone', data.tel);
    formData.append('agree_privacy', 'Y');
    formData.append('agree_terms', 'Y');
    formData.append('member_role_id', '2');
    formData.append('accept_sms', data.marketing[0] ? 'Y' : 'N');
    formData.append('accept_email', data.marketing[1] ? 'Y' : 'N');
    if (refCodeUrlSate) {
      referrerCode = refCodeUrlSate;
      formData.append('referrer_code', refCodeUrlSate);
    } else {
      referrerCode = refCode;
      formData.append('referrer_code', refCode);
    }

    if (referrerCode) {
      axiosClient
        .get(`/data/referrer/is_exist?referrer_code=${referrerCode}`, {
          headers: {
            'Access-Control-Allow-Origin': 'https://api.connectdoctor.co.kr',
            'ngrok-skip-browser-warning': true,
          },
        })
        .then((res) => {
          console.log(res);
          axiosClient
            .post(
              `/func/user/registration?referrer_code=${referrerCode}`,
              formData,
              {
                headers: {
                  'Access-Control-Allow-Origin':
                    'https://api.connectdoctor.co.kr',
                  'ngrok-skip-browser-warning': true,
                },
                withCredentials: true,
              },
            )
            .then((res) => {
              setToken(res.data);
              window.localStorage.removeItem(localKey);
              navigate('/joinSuccess');
              return true;
            })
            .catch((err) => {
              if (err.response.status == 409) {
                alert('이미 가입된 회원입니다.');
                return false;
              }
            });
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    } else if (!referrerCode) {
      axiosClient
        .post(
          `/func/user/registration?referrer_code=${referrerCode}`,
          formData,
          {
            headers: {
              'Access-Control-Allow-Origin': 'https://api.connectdoctor.co.kr',
              'ngrok-skip-browser-warning': true,
            },
            withCredentials: true,
          },
        )
        .then((res) => {
          setToken(res.data);
          window.localStorage.removeItem(localKey);
          navigate('/joinSuccess');
          return true;
        })
        .catch((err) => {
          if (err.response.status == 409) {
            alert('이미 가입된 회원입니다.');
            return false;
          }
        });
    }
  };
  const onError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    alert(errorList[0].message);
    window.scrollTo(0, errorList[0].ref.offsetTop - 150);
    errorList[0].ref.focus();
  };

  return (
    <div className={`${styles.container} ${!isDesktop && styles.isMobile}`}>
      {isDesktop && <Header />}
      {!isDesktop && (
        <div className={styles.backBtn} onClick={() => navigate(-1)}>
          <img src={loginBack} alt="backBtn" />
        </div>
      )}
      <div className={styles.contentWrap}>
        <h2 className={styles.title}>회원가입</h2>
        <div
          className={styles.kakaoLoginBtn}
          onClick={() => (window.location.href = KAKAO_AUTH_URL)}
        >
          <img src={kakaoLogo} alt="kakaoLogo" />
          <span>카카오로 시작하기</span>
        </div>
        <div className={styles.or}>
          <div></div>
          <span>OR</span>
          <div></div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className={styles.joinForm}
        >
          <div className={styles.id}>
            <span>아이디</span>
            <div className={styles.idInputWrap}>
              <input
                type="text"
                placeholder="영문, 숫자 4자 이상"
                {...register('id', {
                  value: '',
                  required: {
                    value: true,
                    message: '아이디를 입력해주세요.',
                  },
                  pattern: {
                    value: idRegex,
                    message: '아이디 형식이 올바르지 않습니다',
                  },
                  onChange: () => {
                    setValue('idCheck', false);
                  },
                })}
              />
              <div
                className={styles.idCheck}
                onClick={handleIdCheck}
                {...register('idCheck', {
                  value: false,
                  validate: () =>
                    watch().idCheck !== true
                      ? '아이디 중복확인을 해주세요.'
                      : true,
                })}
              >
                중복확인
              </div>
            </div>
          </div>
          <div className={styles.pw}>
            <span>비밀번호</span>
            <input
              type="password"
              {...register('pw', {
                value: '',
                required: '비밀번호를 입력해주세요.',
                pattern: {
                  value: passwordRegex,
                  message:
                    '비밀번호는 영문, 숫자, 특수기호(필수x)를 사용하여 8자 이상 30자 이내만 가능합니다.',
                },
              })}
              placeholder="비밀번호 입력"
            />
          </div>
          <div className={styles.pwCheck}>
            <span>비밀번호 확인</span>
            <input
              type="password"
              {...register('pwCheck', {
                value: '',
                validate: () =>
                  watch().pw !== watch().pwCheck
                    ? '비밀번호가 일치하지 않습니다.'
                    : true,
              })}
              placeholder="비밀번호 재입력"
            />
          </div>
          <div className={styles.name}>
            <span>이름</span>
            <input
              type="text"
              {...register('name', {
                value: '',
                required: '이름을 입력해주세요.',
                pattern: {
                  value: nameRegex,
                  message: '이름 형식이 올바르지 않습니다.',
                },
              })}
              placeholder="이름"
            />
          </div>
          <div className={styles.tel}>
            <span>휴대폰 번호</span>
            <div className={styles.telInputWrap}>
              <input
                type="text"
                placeholder="전화번호 입력"
                {...register('tel', {
                  value: '',
                  required: '휴대폰 번호를 입력해주세요.',
                  validate: () =>
                    watch().tel.length < 8 || isNaN(watch().tel)
                      ? '휴대폰 번호가 올바르지 않습니다.'
                      : true,
                  onChange: (e) => {
                    const value = e.currentTarget.value;
                    if (isNaN(Number(value)) && value !== '+')
                      setValue(
                        'tel',
                        watch().tel.slice(0, watch().tel.length - 1),
                      );
                  },
                })}
              />
            </div>
            <p>주문 등과 관련된 중요한 문자가 발송됩니다.</p>
          </div>
          <div className={styles.email}>
            <span>이메일 주소</span>
            <div className={styles.emailInputWrap}>
              <input
                type="text"
                {...register('email1', {
                  value: '',
                  required: '이메일을 입력해주세요.',
                })}
              />
              <span>@</span>
              <div>
                <div className={styles.customSelect}>
                  <input
                    type="text"
                    placeholder="직접입력"
                    {...register('email2', {
                      value: '',
                      onChange: (e) => {
                        setValue('emailCheck', false);
                      },
                    })}
                  />
                  <select
                    defaultValue="default"
                    {...register('emailSelect', {
                      onChange: (e) => {
                        if (e.currentTarget.value == 'directly') {
                          setEmailDirectly(true);
                          setValue('email2', '');
                        } else setValue('email2', e.currentTarget.value);
                        setValue('emailCheck', false);
                      },
                    })}
                    style={{ display: emailDirectly ? 'none' : '' }}
                  >
                    <option disabled value="default">
                      선택해주세요.
                    </option>
                    <option value="naver.com">naver.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="daum.net">daum.net</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="outlook.com">outlook.com</option>
                    <option value="icloud.com">icloud.com</option>
                    <option value="directly">직접입력</option>
                  </select>
                  <img
                    src={selectOpen}
                    alt="selectOpen"
                    style={{
                      display: emailDirectly ? 'none' : '',
                      pointerEvents: 'none',
                    }}
                  />
                  <img
                    src={inputCancel}
                    alt="inputCancel"
                    style={{ display: !emailDirectly ? 'none' : '' }}
                    onClick={() => {
                      setEmailDirectly(false);
                      setValue('emailCheck', false);
                      setValue('email2', '');
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={styles.emailCheck}
              onClick={handleEmailCheck}
              {...register('emailCheck', {
                value: false,
                validate: () =>
                  watch().emailCheck !== true
                    ? '이메일 중복확인을 해주세요.'
                    : true,
              })}
            >
              중복확인
            </div>
          </div>
          <div className={styles.referral}>
            <span>추천인 코드</span>
            <span
              style={{
                color: '#D0D2D6',
              }}
            >
              (선택사항)
            </span>
            <div className={styles.referralInputWrap}>
              <input
                type="text"
                name="referral"
                value={refCodeUrlSate}
                placeholder="추천인 코드 입력"
                onChange={(e) => {
                  setRefCode(e.currentTarget.value);
                  setRefCodeUrlState(e.currentTarget.value);
                }}
              />
            </div>
            <div className={styles.referralJoinMsg}>
              <span
                style={{
                  color: '#666',
                }}
              >
                신규회원 제품 구매 시,{' '}
              </span>
              <span>신규회원 : </span>
              <span>5,000원 즉시 할인</span>
            </div>
            <div className={styles.referralJoinMsg}>
              <span>추천인 : </span>
              <span>5,000원 즉시 지급</span>
            </div>
          </div>
          <div
            className={styles.marketing}
            {...register('marketing', {
              value: [false, false, false],
              validate: () =>
                !watch().marketing.includes(true)
                  ? '마케팅 정보 수신 동의를 확인해주세요.'
                  : true,
            })}
          >
            <span>마케팅 수신 동의</span>
            <ul>
              <li onClick={() => marketingAgree(0)}>
                <div
                  className={`${styles.checkbox} ${
                    watch().marketing && watch().marketing[0] ? styles.hit : ''
                  }`}
                ></div>
                <span>SNS</span>
              </li>
              <li onClick={() => marketingAgree(1)}>
                <div
                  className={`${styles.checkbox} ${
                    watch().marketing && watch().marketing[1] ? styles.hit : ''
                  }`}
                ></div>
                <span>이메일</span>
              </li>
              <li onClick={() => marketingAgree(2)}>
                <div
                  className={`${styles.checkbox} ${
                    watch().marketing && watch().marketing[2] ? styles.hit : ''
                  }`}
                ></div>
                <span>수신 거부</span>
              </li>
            </ul>
            <p>신상품 소식, 이벤트 안내, 다양한 혜택이 제공됩니다.</p>
          </div>
          <div
            className={styles.agreement}
            {...register('agreement', {
              value: [false, false, false, false],
              validate: () =>
                watch().agreement.slice(0, 3).includes(false)
                  ? '필수 약관에 모두 동의해주세요.'
                  : true,
            })}
          >
            <h3
              onClick={() => {
                setValue(
                  'agreement',
                  (() => {
                    let prev = watch().agreement;
                    if (prev.includes(false)) return [true, true, true, true];
                    else return [false, false, false, false];
                  })(),
                );
              }}
            >
              <div
                className={`${styles.customCheckbox} ${
                  watch().agreement && !watch().agreement.includes(false)
                    ? styles.hit
                    : ''
                }`}
              ></div>
              <span>전체 약관 동의</span>
            </h3>
            <ul className={styles.agreementList}>
              <li>
                <div
                  className={styles.left}
                  onClick={() => {
                    setValue(
                      'agreement',
                      (() => {
                        let prev = watch().agreement;
                        prev[0] = !prev[0];
                        return [...prev];
                      })(),
                    );
                  }}
                >
                  <div
                    className={`${styles.customCheckbox} ${
                      watch().agreement && watch().agreement[0]
                        ? styles.hit
                        : ''
                    }`}
                  ></div>
                  <span className={styles.title}>[필수] 이용약관</span>
                </div>
                <span
                  onClick={() => {
                    setAgreementModalOpen(true);
                    setAgreementModalType(0);
                  }}
                >
                  보기
                </span>
              </li>
              <li>
                <div
                  className={styles.left}
                  onClick={() => {
                    setValue(
                      'agreement',
                      (() => {
                        let prev = watch().agreement;
                        prev[1] = !prev[1];
                        return [...prev];
                      })(),
                    );
                  }}
                >
                  <div
                    className={`${styles.customCheckbox} ${
                      watch().agreement && watch().agreement[1]
                        ? styles.hit
                        : ''
                    }`}
                  ></div>
                  <span className={styles.title}>
                    [필수] 개인정보 수집 및 이용 동의
                  </span>
                </div>
                <span
                  onClick={() => {
                    setAgreementModalOpen(true);
                    setAgreementModalType(1);
                  }}
                >
                  보기
                </span>
              </li>
              <li>
                <div
                  className={styles.left}
                  onClick={() => {
                    setValue(
                      'agreement',
                      (() => {
                        let prev = watch().agreement;
                        prev[2] = !prev[2];
                        return [...prev];
                      })(),
                    );
                  }}
                >
                  <div
                    className={`${styles.customCheckbox} ${
                      watch().agreement && watch().agreement[2]
                        ? styles.hit
                        : ''
                    }`}
                  ></div>
                  <span className={styles.title}>
                    [필수] 개인정보 처리ㆍ위탁 동의
                  </span>
                </div>
                <span
                  onClick={() => {
                    setAgreementModalOpen(true);
                    setAgreementModalType(2);
                  }}
                >
                  보기
                </span>
              </li>
              <li>
                <div
                  className={styles.left}
                  onClick={() => {
                    setValue(
                      'agreement',
                      (() => {
                        let prev = watch().agreement;
                        prev[3] = !prev[3];
                        return [...prev];
                      })(),
                    );
                  }}
                >
                  <div
                    className={`${styles.customCheckbox} ${
                      watch().agreement && watch().agreement[3]
                        ? styles.hit
                        : ''
                    }`}
                  ></div>
                  <span className={styles.title}>
                    [선택] 개인정보 제3자 제공 동의
                  </span>
                </div>
                <span
                  onClick={() => {
                    setAgreementModalOpen(true);
                    setAgreementModalType(3);
                  }}
                >
                  보기
                </span>
              </li>
            </ul>
          </div>
          <button className={styles.submitBtn}>가입완료</button>
        </form>
      </div>
      {agreementModalOpen && (
        <JoinAgreementModal
          type={agreementModalType}
          isOpen={setAgreementModalOpen}
        />
      )}
    </div>
  );
}
export default Join;
