import { useForm } from 'react-hook-form';
import Header from '../../components/Header/Header';
import styles from './QuestionnaireEdit.module.scss';
import { isDesktop, isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';
import prevNavi from '../../assets/prevNavi.png';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { ISavedPatientType } from '../Questionnaire/Questionnaire';
import { IAllItemsType } from '../ProductDetail/ProductDetail';

function QuestionnaireEdit() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z ]+$/;
  const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const [selectedAllItems, setSelectedAllItems] = useState<IAllItemsType[]>([]);
  const [isGlobal, setIsGlobal] = useState(false);
  // 보조제만 선택했는지 확인
  const [isSupplyment, setIsSupplyment] = useState(false);

  useEffect(() => {
    axiosClient.get(`/data/prescription?order_id=${orderId}`).then((res) => {
      let result: ISavedPatientType = res.data.data;
      if (Object.keys(result).length == 0) return;
      if (nameRegex.test(result.user_name))
        setValue('patientName', result.user_name);
      setValue('patientTel', result.phone.trim().replace(/-/g, ''));
      setValue('patientHeight', String(result.height));
      setValue('patientWeight', String(result.weight));
      setValue('pregnancy', result.is_pregnant == 'Y' ? true : false);
      setValue('dailyCoffee', result.daily_coffee_intake);
      setValue('caffeineSensitivity', result.caffeine_sensitivity);
      setValue(
        'dietPillsExperience',
        (() => {
          let newVal = [false, false, false, false];
          result.diet_pills_experience.split(',').map((num: string) => {
            newVal[Number(num) - 1] = true;
          });
          return newVal;
        })(),
      );
      setValue('dietPillsLevel', result.diet_pills_level);
      setValue('sideEffects', result.diet_pills_side_effects || '');
      setValue('disease', result.is_disease == 'Y' ? true : false);
      setValue('diseaseDetail', result.disease || '');
      setValue('medicine', result.is_taking_medicines == 'Y' ? true : false);
      setValue('medicineDetail', result.taking_medicines || '');
      setValue('patientSecurityNumber1', result.social_number.split('-')[0]);
      setValue('patientSecurityNumber2', result.social_number.split('-')[1]);
      setValue('reason', result.question || '');
      setIsGlobal(result.is_oversea === 'N' ? false : true);
      if (result.is_oversea === 'Y') {
        setValue(
          'snsIdMethod',
          result.sns_type === 'kakao' ? 'kakaotalk' : 'line',
        );
        setValue('snsId', result.sns_id);
      }
    });
  }, []);

  useEffect(() => {
    // 보조제만 선택한 경우
    if (
      watch().dietPillsExperience[3] === true &&
      watch()
        .dietPillsExperience.slice(0, 3)
        .every((item: boolean) => item === false)
    ) {
      setIsSupplyment(true);
      setValue('dietPillsLevel', 0);
      setValue('sideEffects', '');
    } else setIsSupplyment(false);
  }, [watch().dietPillsExperience, watch().sideEffects]);

  const validateSecurityNum = () => {
    const securityNum = `${watch().patientSecurityNumber1}${
      watch().patientSecurityNumber2
    }`;

    if (securityNum === '0'.repeat(13)) return true;

    if (securityNum.length !== 13 || isNaN(Number(securityNum))) {
      setError(
        'patientSecurityNumber1',
        {
          message: '주민등록번호를 정확하게 입력해주세요.',
        },
        { shouldFocus: true },
      );
      alert('주민등록번호를 정확하게 입력해주세요.(13자리)');
      return false;
    }

    const multiply = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
    let sum = 0;

    for (let i = 0; i < 12; i++) {
      sum += parseInt(securityNum[i]) * multiply[i];
    }

    const remain = sum % 11;
    // 10이 나오면 => 0으로 해야해서
    const checkCode = (11 - remain) % 10;

    const isSecurity = parseInt(securityNum[12]) === checkCode;
    if (!isSecurity) {
      setError(
        'patientSecurityNumber1',
        {
          message: '유효하지 않은 주민등록번호입니다.',
        },
        { shouldFocus: true },
      );
      alert('유효하지 않은 주민등록번호입니다.');
    }
    return isSecurity;
  };

  const onSubmit = async (data: any) => {
    if (!orderId) return;
    // if (!validateSecurityNum()) return;
    let formData = new FormData();

    formData.append('order_id', orderId);
    formData.append('is_reservation', 'N');
    formData.append('user_name', watch().patientName);
    formData.append('phone', watch().patientTel);
    formData.append('height', watch().patientHeight);
    formData.append('weight', watch().patientWeight);
    formData.append('is_pregnant', watch().pregnancy ? 'Y' : 'N');
    formData.append('daily_coffee_intake', String(watch().dailyCoffee));
    formData.append(
      'caffeine_sensitivity',
      String(watch().caffeineSensitivity),
    );
    watch().dietPillsExperience.map((val: boolean, idx: number) => {
      if (val) formData.append('diet_pills_experience', String(idx + 1));
    });
    if (!watch().dietPillsExperience[0] && !isSupplyment) {
      formData.append('diet_pills_level', String(watch().dietPillsLevel));
      formData.append(
        'diet_pills_side_effects',
        String(watch().sideEffects || ''),
      );
    }
    formData.append('is_disease', watch().disease ? 'Y' : 'N');
    formData.append(
      'disease',
      watch().disease ? watch().diseaseDetail || '' : '',
    );
    formData.append('is_taking_medicines', watch().medicine ? 'Y' : 'N');
    formData.append(
      'taking_medicines',
      watch().medicine ? watch().medicineDetail || '' : '',
    );
    formData.append(
      'social_number',
      watch().patientSecurityNumber1 + '-' + watch().patientSecurityNumber2,
    );
    formData.append('question', watch().reason);
    if (isGlobal) {
      formData.append('is_oversea', isGlobal ? 'Y' : 'N');
      formData.append(
        'sns_type',
        watch().snsIdMethod === 'kakaotalk' ? 'kakao' : 'line',
      );
      formData.append('sns_id', watch().snsId);
    }
    await axiosClient
      .post('/func/prescription', formData)
      .then((res) => {
        alert('문진표가 정상적으로 수정되었습니다.');
        return navigate(-1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    window.scrollTo(
      0,
      window.scrollY + errorList[0].ref.getBoundingClientRect().top - 150,
    );
    alert(errorList[0].message);
  };

  return (
    <div className={`${styles.container} ${!isDesktop ? styles.isMobile : ''}`}>
      <Header />
      <div className={styles.contentArea}>
        <div className={styles.headerWrap}>
          <div className={styles.header}>
            {isMobile && (
              <img onClick={() => navigate(-1)} src={prevNavi} alt="prev" />
            )}
            <span>문진표 확인 및 변경</span>
            {isMobile && <div></div>}
          </div>
        </div>
        <div className={styles.left}>
          <div className={styles.questionnaireWrap}>
            <form
              className={styles.questionnaireForm}
              onSubmit={(e) => e.preventDefault()}
            >
              <label className={`${styles.label} ${styles.nameLabel}`}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>이름</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.patientName ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="이름"
                    className={`${styles.normalInput} name`}
                    {...register('patientName', {
                      value: '',
                      required: {
                        value: true,
                        message: '이름은 필수 입력사항입니다.',
                      },
                      pattern: {
                        value: nameRegex,
                        message: '올바른 이름 형식이 아닙니다.',
                      },
                    })}
                  />
                </div>
                <p>한글, 영문, 공백만 사용 가능합니다.</p>
              </label>
              <label className={styles.phoneNumberLabel}>
                <div className={`${styles.labelWrap} ${styles.phoneLabelWrap}`}>
                  <div>
                    <span>*</span>
                    <span>연락처</span>
                  </div>
                  <div
                    className={`${styles.globalCheckWrap} ${
                      isGlobal ? styles.hit : ''
                    }`}
                    onClick={() => {
                      setIsGlobal((prev) => !prev);
                    }}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>지금 해외에 있어서 전화를 받기가 어려워요.</span>
                  </div>
                </div>
                <div className={styles.box}>
                  {isGlobal && (
                    <div className={styles.socialWrap}>
                      <div className={styles.socialMethodWrap}>
                        <div
                          className={styles.customCheckBoxListWrap}
                          {...register('snsIdMethod', {
                            value: 'kakaotalk',
                            validate: () =>
                              !watch().snsIdMethod
                                ? '카카오톡 또는 라인아이디 방법을 선택해주세요.'
                                : true,
                          })}
                        >
                          <div
                            className={`${styles.customCheckBoxWrap} ${
                              watch().snsIdMethod === 'kakaotalk'
                                ? styles.hit
                                : ''
                            }`}
                            onClick={() => {
                              if (watch().snsIdMethod !== 'kakaotalk')
                                setValue('snsId', '');
                              setValue('snsIdMethod', 'kakaotalk');
                            }}
                          >
                            <div className={styles.customCheckBox}></div>
                            <span>KakaoTalk ID</span>
                          </div>
                          <div
                            className={`${styles.customCheckBoxWrap} ${
                              watch().snsIdMethod === 'line' ? styles.hit : ''
                            }`}
                            onClick={() => {
                              if (watch().snsIdMethod !== 'line')
                                setValue('snsId', '');
                              setValue('snsIdMethod', 'line');
                            }}
                          >
                            <div className={styles.customCheckBox}></div>
                            <span>Line ID</span>
                          </div>
                        </div>
                      </div>
                      <label className={styles.labelWrap}>
                        <div
                          className={`${styles.socialInputWrap} ${
                            errors.snsId ? styles.validationError : ''
                          }`}
                        >
                          <input
                            type="text"
                            placeholder="카카오톡 또는 라인아이디"
                            className={styles.normalInput}
                            {...register('snsId', {
                              value: '',
                              required: {
                                value: true,
                                message:
                                  '카카오톡 또는 라인 아이디를 입력해주세요.',
                              },
                            })}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                  <div
                    className={`${styles.phoneNumberInuptWrap} ${
                      errors.patientTel ? styles.validationError : ''
                    }`}
                  >
                    <input
                      type="text"
                      placeholder=""
                      className={`${styles.normalInput} tel`}
                      {...register('patientTel', {
                        value: '',
                        required: {
                          value: true,
                          message: '연락처는 필수 입력사항입니다.',
                        },
                        minLength: {
                          value: 8,
                          message: '연락처를 올바르게 입력해주세요.',
                        },
                        pattern: !isGlobal
                          ? {
                              value: phoneRegex,
                              message: '올바른 연락처 형식이 아닙니다.',
                            }
                          : undefined,
                        onChange: (e) => {
                          let value = e.currentTarget.value;
                          if (!/^[0-9]+$/g.test(value)) {
                            setValue(
                              'patientTel',
                              value.slice(0, value.length - 1),
                            );
                          }
                        },
                      })}
                    />
                  </div>
                  <div className={styles.telAlertWrap}>
                    <p>
                      의료진이 진료를 위해 전화를 드리므로 이름과 연락처를
                      {isMobile && <br />} 정확하게 기재해주셔야 빠른 진료를
                      받으실수 있습니다.
                    </p>
                    {isGlobal && (
                      <p className={styles.globalTelAlert}>
                        * 해외 체류로 인해 카카오톡/라인 ID를 입력하신 경우,
                        보이스톡으로 진료를 진행합니다. 반드시{' '}
                        <span className={styles.hit}>아이디 검색 허용</span>을
                        미리 설정해 주세요.
                      </p>
                    )}
                  </div>
                </div>
              </label>
              <label className={styles.securityNumberLabel}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>주민등록번호</span>
                </div>
                <div>
                  <div
                    className={`${styles.securityNumberInuptWrap} ${
                      errors.patientSecurityNumber1 ||
                      errors.patientSecurityNumber2
                        ? styles.validationError
                        : ''
                    }`}
                  >
                    <input
                      type="number"
                      {...register('patientSecurityNumber1', {
                        value: '',
                        required: {
                          value: true,
                          message: '주민등록번호를 입력해주세요.',
                        },
                        maxLength: {
                          value: 6,
                          message:
                            '주민등록번호 앞자리를 6글자로 작성해주세요.',
                        },
                        minLength: {
                          value: 6,
                          message:
                            '주민등록번호 앞자리를 6글자로 작성해주세요.',
                        },
                        pattern: {
                          value: /^[0-9]{6}$/,
                          message: '주민등록번호는 숫자만 입력 가능합니다.',
                        },
                        onChange: (e) => {
                          if (e.currentTarget.value.length > 6)
                            setValue(
                              'patientSecurityNumber1',
                              e.currentTarget.value.slice(0, 6),
                            );
                        },
                      })}
                      max={9999}
                      maxLength={6}
                      placeholder="000000"
                    />
                    <>
                      <span>-</span>
                      <input
                        type="password"
                        autoComplete="off"
                        {...register('patientSecurityNumber2', {
                          value: '',
                          required: {
                            value: true,
                            message: '주민등록번호를 입력해주세요.',
                          },
                          minLength: {
                            value: 7,
                            message:
                              '주민등록번호 뒷자리를 7글자로 작성해주세요.',
                          },
                          onChange: (e) => {
                            if (isNaN(Number(e.currentTarget.value)))
                              setValue(
                                'patientSecurityNumber2',
                                e.currentTarget.value.slice(
                                  0,
                                  e.currentTarget.value.length - 1,
                                ),
                              );
                            if (e.currentTarget.value.length > 7)
                              setValue(
                                'patientSecurityNumber2',
                                e.currentTarget.value.slice(0, 7),
                              );
                          },
                        })}
                        placeholder="0000000"
                      />
                    </>
                  </div>
                  <div className={styles.priNumAlertWrap}>
                    <p>
                      비대면 진료를 받기 위해서는 의료법에 의해 의료기관이
                      주민번호를 확인합니다. 커넥트 닥터는 해당 정보를 확인할 수
                      없습니다.
                    </p>
                    <p className={`${isGlobal && styles.globalPriNumAlert} `}>
                      *해외거주자 분들은 000000-0000000 으로 입력해주세요.
                    </p>
                  </div>
                </div>
              </label>
              <label className={styles.label}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>키</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.patientHeight ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    className={`${styles.normalInput} height`}
                    {...register('patientHeight', {
                      value: '',
                      required: {
                        value: true,
                        message: '키를 입력해주세요.',
                      },
                      onChange: (e) => {
                        let value = e.currentTarget.value;
                        if (isNaN(Number(e.currentTarget.value)))
                          setValue(
                            'patientHeight',
                            value.slice(0, value.length - 1),
                          );
                      },
                    })}
                  />
                  <span className={styles.inputUnit}>cm</span>
                </div>
              </label>
              <label className={styles.label}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>체중</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.patientWeight ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    className={`${styles.normalInput} weight`}
                    {...register('patientWeight', {
                      value: '',
                      required: {
                        value: true,
                        message: '체중을 입력해주세요.',
                      },
                      onChange: (e) => {
                        let value = e.currentTarget.value;
                        if (isNaN(Number(e.currentTarget.value)))
                          setValue(
                            'patientWeight',
                            value.slice(0, value.length - 1),
                          );
                      },
                    })}
                    // value={patientWeight}
                    // onChange={(e) => {
                    //   let value = e.currentTarget.value;
                    //   if (isNaN(Number(e.currentTarget.value))) return;
                    //   setPatientWeight(value);
                    // }}
                    // onBlur={() => setPatientWeight((prev) => prev.trim())}
                  />
                  <span className={styles.inputUnit}>kg</span>
                </div>
              </label>
              <label className={`${styles.label} ${styles.reasonLabel}`}>
                <div className={styles.labelWrap}>
                  <span>*</span>
                  <span>상담사유</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    errors.reason ? styles.validationError : ''
                  }`}
                >
                  <textarea
                    className={`${styles.reason} reason`}
                    {...register('reason', {
                      value: '',
                      required: {
                        value: true,
                        message: '상담사유를 입력해주세요.',
                      },
                    })}
                    placeholder="다이어트"
                  ></textarea>
                </div>
              </label>
              <div
                className={`${styles.pregnancyLabel} ${
                  errors.pregnancy ? styles.warning : ''
                }`}
                {...register('pregnancy', {
                  required: {
                    value: watch().pregnancy == undefined,
                    message: '임신 여부를 선택해주세요.',
                  },
                })}
              >
                <div>
                  <span>*</span>
                  <span>임신 여부</span>
                </div>
                <div className={styles.checkOutWrap}>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      watch().pregnancy ? styles.hit : ''
                    } pregnancy`}
                    onClick={() => setValue('pregnancy', true)}

                    // onClick={() => setPregnancy(true)}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>예</span>
                  </div>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      watch().pregnancy === false ? styles.hit : ''
                    }`}
                    onClick={() => setValue('pregnancy', false)}

                    // onClick={() => setPregnancy(false)}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>아니오</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={`${styles.prescriptionDetailWrap}`}>
            <label
              className={`${styles.label} ${
                errors.dailyCoffee ? styles.warning : ''
              } dailyCoffee`}
              {...register('dailyCoffee', {
                value: 0,
                validate: () =>
                  !/^[1-9]$/g.test(watch().dailyCoffee)
                    ? '하루 섭취 커피량을 선택해주세요.'
                    : true,
              })}
            >
              <div className={styles.labelWrap}>
                <span>*</span>
                <span>하루 커피량</span>
              </div>
              <ul
                className={`${styles.checkboxArea} ${
                  isMobile ? styles.isMobile : ''
                }`}
              >
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 1 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 1)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>0잔</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 2 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 2)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>1잔</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 3 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 3)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>2잔</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dailyCoffee == 4 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('dailyCoffee', 4)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>3잔 이상</span>
                </li>
              </ul>
            </label>
            <label
              className={`${styles.label} ${styles.caffeineSensitivity} ${
                errors.caffeineSensitivity ? styles.warning : ''
              } caffeineSensitivity`}
              {...register('caffeineSensitivity', {
                value: 0,
                validate: () =>
                  !/^[1-9]$/g.test(watch().caffeineSensitivity)
                    ? '카페인 예민도를 선택해주세요.'
                    : true,
              })}
            >
              <div className={styles.labelWrap}>
                <span>*</span>
                <span>카페인 예민도</span>
              </div>
              <ul
                className={`${styles.checkboxArea} ${
                  isMobile ? styles.isMobile : ''
                }`}
              >
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().caffeineSensitivity == 1 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('caffeineSensitivity', 1)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>없음</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().caffeineSensitivity == 2 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('caffeineSensitivity', 2)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>약간</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().caffeineSensitivity == 3 ? styles.hit : ''
                  }`}
                  onClick={() => setValue('caffeineSensitivity', 3)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>예민</span>
                </li>
              </ul>
            </label>
            <label
              className={`${styles.label} ${
                errors.dietPillsExperience ? styles.warning : ''
              } dietPillsExperience`}
              {...register('dietPillsExperience', {
                value: [false, false, false, false],
                validate: () =>
                  watch().dietPillsExperience &&
                  !watch().dietPillsExperience.includes(true)
                    ? '다이어트 약 복용 경험을 선택해주세요.'
                    : true,
              })}
            >
              <div className={styles.labelWrap}>
                <span>*</span>
                <span>다이어트 약 복용 경험</span>
              </div>
              <ul
                className={`${styles.checkboxArea} ${
                  isMobile ? styles.isMobile : ''
                }`}
              >
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[0]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      true,
                      false,
                      false,
                      false,
                    ]);
                    setValue('dietPillsLevel', 0);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>없음</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[1]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      false,
                      !watch().dietPillsExperience[1],
                      watch().dietPillsExperience[2],
                      watch().dietPillsExperience[3],
                    ]);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>한약</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[2]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      false,
                      watch().dietPillsExperience[1],
                      !watch().dietPillsExperience[2],
                      watch().dietPillsExperience[3],
                    ]);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>양약</span>
                </li>
                <li
                  className={`${styles.customCheckBoxWrap} ${
                    watch().dietPillsExperience &&
                    watch().dietPillsExperience[3]
                      ? styles.hitCheck
                      : ''
                  }`}
                  onClick={() => {
                    setValue('dietPillsExperience', [
                      false,
                      watch().dietPillsExperience[1],
                      watch().dietPillsExperience[2],
                      !watch().dietPillsExperience[3],
                    ]);
                  }}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>보조제</span>
                </li>
              </ul>
            </label>
            {watch().dietPillsExperience &&
              !watch().dietPillsExperience[0] &&
              !isSupplyment &&
              watch().dietPillsExperience.includes(true) && (
                <>
                  <label
                    className={`${styles.label} ${
                      errors.dietPillsLevel ? styles.warning : ''
                    } dietPillsLevel`}
                    {...register('dietPillsLevel', {
                      value: 0,
                      validate: () =>
                        !/^[1-9]$/g.test(watch().dietPillsLevel)
                          ? '복용 약 단계 및 강도를 선택해주세요.'
                          : true,
                      // required: {
                      //   value:
                      //     watch().dietPillsExperience &&
                      //     !watch().dietPillsExperience[0] &&
                      //     true,
                      //   message: '복용 약 단계 및 강도를 선택해주세요.',
                      // },
                    })}
                  >
                    <div className={styles.labelWrap}>
                      <span>*</span>
                      <span>복용 약 단계 및 강도</span>
                    </div>
                    <ul
                      className={`${styles.checkboxArea} ${
                        isMobile ? styles.isMobile : ''
                      }`}
                    >
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 1 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 1)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>모름</span>
                      </li>
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 2 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 2)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>약함</span>
                      </li>
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 3 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 3)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>보통</span>
                      </li>
                      <li
                        className={`${styles.customCheckBoxWrap} ${
                          watch().dietPillsLevel == 4 ? styles.hit : ''
                        }`}
                        onClick={() => setValue('dietPillsLevel', 4)}
                      >
                        <div className={styles.customCheckBox}></div>
                        <span>강함</span>
                      </li>
                    </ul>
                  </label>
                  <label
                    className={`${styles.label} ${
                      errors.sideEffects ? styles.warning : ''
                    }`}
                  >
                    <div className={styles.labelWrap}>
                      <span>*</span>
                      <span>복용 시 불편사항</span>
                    </div>
                    <div
                      className={`${styles.inputWrap} ${
                        errors.sideEffects ? styles.validationError : ''
                      }`}
                    >
                      <textarea
                        className={`${styles.sideEffects} sideEffects`}
                        {...register('sideEffects', {
                          value: '',
                          required: {
                            value:
                              watch().dietPillsExperience &&
                              !watch().dietPillsExperience[0] &&
                              true,
                            message:
                              '다이어트 약 복용시 불편사항을 입력해주세요.',
                          },
                        })}
                      ></textarea>
                    </div>
                  </label>
                </>
              )}
          </div>
          <div
            className={`${styles.diseaseCheckWrap} ${
              errors.disease ? styles.warning : ''
            } disease`}
            {...register('disease', {
              required: {
                value: watch().disease == undefined,
                message: '질병 여부를 선택해주세요.',
              },
            })}
          >
            <div className={styles.checkArea}>
              <div>
                <span>*</span>
                <span>질병 여부</span>
              </div>
              <div className={styles.checkOutWrap}>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().disease ? styles.hit : ''
                  }`}
                  onClick={() => setValue('disease', true)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>예</span>
                </div>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().disease === false ? styles.hit : ''
                  }`}
                  onClick={() => setValue('disease', false)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>아니오</span>
                </div>
              </div>
            </div>
            {watch().disease && (
              <>
                <span className={styles.sectionGuide}>
                  앓고 있는 질환을 상세히 작성해주세요.
                </span>
                <textarea
                  placeholder="예) 불면증, 당뇨"
                  {...register('diseaseDetail', {
                    value: '',
                  })}
                />
              </>
            )}
          </div>
          <div
            className={`${styles.medicineCheckWrap} ${
              errors.medicine ? styles.warning : ''
            } medicine`}
            {...register('medicine', {
              required: {
                value: watch().medicine == undefined,
                message: '복용중인 약 여부를 선택해주세요.',
              },
            })}
          >
            <div className={styles.checkArea}>
              <div>
                <span>*</span>
                <span>복용중인 약</span>
              </div>
              <div className={styles.checkOutWrap}>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().medicine ? styles.hit : ''
                  }`}
                  onClick={() => setValue('medicine', true)}
                  // onClick={() => setMedicine(true)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>예</span>
                </div>
                <div
                  className={`${styles.customCheckBoxWrap} ${
                    watch().medicine === false ? styles.hit : ''
                  }`}
                  onClick={() => setValue('medicine', false)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>아니오</span>
                </div>
              </div>
            </div>
            {watch().medicine && (
              <>
                <span className={styles.sectionGuide}>
                  복용중인 약의 종류를 작성해주세요
                </span>
                <textarea
                  placeholder="예) 수면제(벤조디아제핀)"
                  // value={medicineDetail}
                  // onChange={(e) => setMedicineDetail(e.currentTarget.value)}
                  {...register('medicineDetail', {
                    value: '',
                  })}
                />
              </>
            )}
          </div>
          <div
            className={`${styles.cautionWrap} ${
              errors.cautionCheck ? styles.isError : ''
            }`}
          >
            <div className={styles.labelWrap}>
              <span>*</span>
              <span>주의사항</span>
            </div>
            <ul>
              <li>
                <span>1.&nbsp;</span>
                <span>
                  빠른 감량 원하시는 분들은 식사 1시간 전 (점심, 저녁 전) 으로
                  하루 2번 복용이 원칙입니다.
                </span>
              </li>
              <li>
                <span>2.&nbsp;</span>
                <span>
                  수면이 불편하신 분들은 저녁복용을 오후 4시로 당겨서
                  복용합니다.
                </span>
              </li>
              <li>
                <span>3.&nbsp;</span>
                <span>
                  초기 적응기간 3~4일 동안 예민하게 느끼시는 분들은 복용량을
                  절반으로 줄여 드시다가 적응 후 정량 드시면 됩니다.
                </span>
              </li>
            </ul>
            <div
              className={`${styles.confirm} ${
                watch().cautionCheck ? styles.hit : ''
              }`}
              {...register('cautionCheck', {
                value: false,
                required: {
                  value: watch().cautionCheck == false,
                  message: '주의사항을 확인 후 체크 버튼을 눌러주세요.',
                },
              })}
              onClick={() => setValue('cautionCheck', !watch().cautionCheck)}
            >
              <div className={styles.customCheckBox}></div>
              <span>복용에 관한 주의사항을 확인했습니다.</span>
            </div>
          </div>
          <div
            className={`${styles.cautionWrap} ${
              errors.privacyCheck ? styles.isError : ''
            }`}
          >
            <div className={styles.labelWrap}>
              <span>*</span>
              <span>개인정보 기입 확인 및 안내사항</span>
            </div>
            <ul>
              <li>
                <span>1.&nbsp;</span>
                <span>
                  개인정보(이름, 연락처 등)를 정확하게 입력했는지 한 번 더
                  확인해 주세요. 개인정보 오기입 시, 불이익(비대면 진료 지연
                  등)이 발생할 수 있습니다.
                </span>
              </li>
              <li>
                <span>2.&nbsp;</span>
                <span>
                  주문 완료 후 비대면 진료 시{' '}
                  <span className={styles.hit}>제품의 단계는 변경 가능</span>
                  하니, 부담 없이 주문해 주세요.
                </span>
              </li>
            </ul>
            <div
              className={`${styles.confirm} ${
                watch().privacyCheck ? styles.hit : ''
              }`}
              {...register('privacyCheck', {
                value: false,
                required: {
                  value: watch().privacyCheck == false,
                  message: '개인정보 기입 확인 후 체크 버튼을 눌러주세요.',
                },
              })}
              onClick={() => setValue('privacyCheck', !watch().privacyCheck)}
            >
              <div className={styles.customCheckBox}></div>
              <span>개인정보를 정확하게 입력했습니다.</span>
            </div>
          </div>
          <div
            className={`${styles.btnWrap} ${!isDesktop ? styles.isMobile : ''}`}
          >
            <button
              className={styles.submitBtn}
              onClick={handleSubmit(onSubmit, onSubmitError)}
            >
              변경하기
            </button>
          </div>
        </div>
        {/* <div className={styles.right}>결제페이지로 이동</div> */}
      </div>
    </div>
  );
}

export default QuestionnaireEdit;
