import { isMobile } from 'react-device-detect';
import Header from '../../components/Header/Header';
import styles from './WeightPoint.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import chart from '../../assets/weightChallenge/chart.png';
import list from '../../assets/weightChallenge/list.png';
import prevBtn from '../../assets/weightChallenge/prevBtn.png';
import dateLeft from '../../assets/weightChallenge/dateLeft.png';
import dateRight from '../../assets/weightChallenge/dateRight.png';
import dateLeftNo from '../../assets/weightChallenge/dateLeftNo.png';
import dateRightNo from '../../assets/weightChallenge/dateRightNo.png';

export interface IWeightType {
  contet: string;
  created_at: string;
  start_date: string;
  end_date: string;
  loseweight: string;
}

export interface IPointType {
  week: number;
  month: string;
  point: number;
}

export interface IWeightPointType extends IWeightType, IPointType {}

function WeightPoint() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabMenuSelect, setTabMenuSelect] = useState('');
  const [monthState, setMonthState] = useState<Number>(); // 월 구하기
  const [yearState, setYearState] = useState<Number>(); // 년도 구하기
  const [challengePartiDay, setChallengePartiDay] = useState(); // 챌린지 참여일
  const [weeklyWeightPoint, setWeeklyWeightPoint] = useState<
    IWeightPointType[]
  >([]); // 몸무게 + 포인트 배열
  const [monthlyWeightPoint, setMonthlyWeightPoint] = useState<
    IWeightPointType[]
  >([]);
  const [activeItem, setActiveItem] = useState('weekReward');
  const [sumWeekPoint, setSumWeekPoint] = useState<String>('0');
  const [sumMonthPoint, setSumMonthPoint] = useState<String>('0');

  const nowDate = new Date();
  // const nowDate = new Date('2023-12-04');
  let nowYear = nowDate.getFullYear();
  let nowMonth = nowDate.getMonth() + 1;

  let nowYearStore = nowDate.getFullYear();

  let pointWeekSum = 0;
  let pointMonthSum = 0;

  let monthRef = useRef<number>();
  let yearRef = useRef<number>();

  let weekStartDate: string | number | Date;
  let weekEndDate: string | number | Date;
  let monthStartDate: string | number | Date;
  let monthEndDate: string | number | Date;
  let challengeLogLength: number;
  let weekData;
  let weekPoint: { [x: string]: any };
  let monthData;
  let monthPoint: { [x: string]: any };
  let weightPointArr;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get('date-tab')) setTabMenuSelect('주별');
    else if (params.get('date-tab') === 'month') {
      setTabMenuSelect('월별');
      setActiveItem('monthReward');
    }
  }, [location]);

  const weeklyPointImport = () => {
    pointWeekSum = 0;
    axiosClient
      .get(
        `/data/point/history/challenge?date=${nowYear}-${nowMonth}&offset=0&limit=30`,
      )
      .then((res) => {
        for (let i = 0; i < res.data.data.weeks.length; i++) {
          pointWeekSum += Number(res.data.data.weeks[i].point);
        }
        setSumWeekPoint(
          pointWeekSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        );
        weekPoint = res.data.data.weeks;

        weeklyWeightImport();
      });
  };

  const monthlyPointImport = () => {
    pointMonthSum = 0;
    axiosClient
      .get(`/data/point/history/challenge?date=${nowYear}&offset=0&limit=30`)
      .then((res) => {
        for (let i = 0; i < res.data.data.months.length; i++) {
          pointMonthSum += Number(res.data.data.months[i].point);
        }
        setSumMonthPoint(
          pointMonthSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        );

        monthPoint = res.data.data.months;

        monthlyWeightImport();
      });
  };

  useEffect(() => {
    // 포인트 조회 (주별)
    weeklyPointImport();

    // 포인트 조회 (월별)
    monthlyPointImport();
  }, []);

  const weeklyWeightImport = () => {
    axiosClient
      .get(`/data/challenge/my/1?date_str=${nowYear}-${nowMonth}-01`)
      .then(function (res) {
        setChallengePartiDay(res.data.challenge_participation_period);

        weekData = res.data.challenge_log;

        weightPointArr =
          weekData &&
          weekData.map((item: any, idx: any) => ({
            ...item,
            ...weekPoint[idx],
          }));
        setWeeklyWeightPoint(weightPointArr);

        challengeLogLength = res.data.challenge_log.length;
        weekStartDate = res.data.challenge_log[0].start_date;
        weekEndDate = res.data.challenge_log[challengeLogLength - 1].end_date;

        if (nowDate < new Date(weekStartDate)) {
          if (nowMonth !== 1) {
            nowMonth -= 1;
            setMonthState(nowMonth);
          } else {
            nowMonth = 12;
            nowYear -= 1;
            setMonthState(nowMonth);
            setYearState(nowYear);
          }
          monthRef.current = nowMonth;
          yearRef.current = nowYear;
        } else if (nowDate > new Date(weekEndDate)) {
          if (nowMonth !== 12) {
            nowMonth += 1;
            setMonthState(nowMonth);
          } else {
            nowMonth = 1;
            nowYear += 1;
            setMonthState(nowMonth);
            setYearState(nowYear);
          }
          monthRef.current = nowMonth;
          yearRef.current = nowYear;
        } else {
          monthRef.current = nowMonth;
          yearRef.current = nowYear;
          return;
        }
        weeklyPointImport();
      });
  };

  const monthlyWeightImport = () => {
    axiosClient
      .get(`/data/challenge/my/1?date_str=${nowYear}-01-01&unit=monthly`)
      .then(function (res) {
        monthData = res.data.challenge_log;

        weightPointArr =
          monthData &&
          monthData.map((item: any, idx: any) => ({
            ...item,
            ...monthPoint[idx],
          }));
        setMonthlyWeightPoint(weightPointArr);

        challengeLogLength = res.data.challenge_log.length;
        monthStartDate = res.data.challenge_log[0].start_date;
        monthEndDate = res.data.challenge_log[challengeLogLength - 1].end_date;

        if (nowDate < new Date(monthStartDate)) {
          if (nowYear === nowYearStore) nowYear -= 1;
          yearRef.current = nowYear;
          setYearState(nowYear);
        } else if (nowDate > new Date(monthEndDate)) {
          if (nowYear === nowYearStore) nowYear += 1;
          yearRef.current = nowYear;
          setYearState(nowYear);
        } else {
          yearRef.current = nowYear;

          return;
        }
        monthlyPointImport();
      });
  };

  useEffect(() => {
    // 월 계산
    const month = nowDate.getMonth() + 1;
    setMonthState(month);

    // 년 계산
    const year = nowDate.getFullYear();
    setYearState(year);
  }, []);

  let monthStateChange;
  let yearStateChange: number;
  let monthStateChangeZero: string | number;

  const weeklyClickWeightImport = () => {
    axiosClient
      .get(
        `/data/challenge/my/1?date_str=${yearStateChange}-${monthStateChangeZero}-01`,
      )
      .then(function (res) {
        weekData = res.data.challenge_log;

        weightPointArr =
          weekData &&
          weekData.map((item: any, idx: any) => ({
            ...item,
            ...weekPoint[idx],
          }));
        setWeeklyWeightPoint(weightPointArr);
      });
  };

  const monthlyClickWeightImport = () => {
    axiosClient
      .get(`/data/challenge/my/1?date_str=${nowYear}-01-01&unit=monthly`)
      .then(function (res) {
        monthData = res.data.challenge_log;

        weightPointArr =
          monthData &&
          monthData.map((item: any, idx: any) => ({
            ...item,
            ...monthPoint[idx],
          }));
        setMonthlyWeightPoint(weightPointArr);
      });
  };

  // 주별 날짜 변경
  const dateWeekClick = (dateNum: number) => {
    monthStateChange = Number(monthState);
    yearStateChange = Number(yearState);

    if (dateNum === -1) {
      if (Number(monthState) > 1 && Number(monthState) < 13) {
        monthStateChange = Number(monthState) + dateNum;
        setMonthState(Number(monthState) + dateNum);
      } else if (Number(monthState) === 1) {
        monthStateChange = Number(monthState) + 11;
        yearStateChange = Number(yearState) + dateNum;
        setMonthState(Number(monthState) + 11);
        setYearState(Number(yearState) + dateNum);
      }
    } else if (dateNum === +1) {
      if (Number(monthState) > 0 && Number(monthState) < 12) {
        monthStateChange = Number(monthState) + dateNum;
        setMonthState(Number(monthState) + dateNum);
      } else if (Number(monthState) === 12) {
        monthStateChange = Number(monthState) - 11;
        yearStateChange = Number(yearState) + dateNum;
        setMonthState(Number(monthState) - 11);
        setYearState(Number(yearState) + dateNum);
      }
    }

    monthStateChangeZero =
      monthStateChange <= 9 ? '0' + monthStateChange : monthStateChange;

    nowMonth = Number(monthStateChangeZero);
    nowYear = yearStateChange;

    // 포인트 조회
    // weeklyPointImport();
    axiosClient
      .get(
        `/data/point/history/challenge?date=${nowYear}-${nowMonth}&offset=0&limit=30`,
      )
      .then((res) => {
        for (let i = 0; i < res.data.data.weeks.length; i++) {
          pointWeekSum += Number(res.data.data.weeks[i].point);
        }
        setSumWeekPoint(
          pointWeekSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        );
        weekPoint = res.data.data.weeks;
        weeklyClickWeightImport();
      });
    // 주별 평균 / 감량
  };

  // 월별 날짜 변경
  const dateMonthClick = (dateNum: number) => {
    let yearStateChange = Number(yearState);

    setYearState(Number(yearState) + dateNum);
    yearStateChange += dateNum;

    axiosClient
      .get(
        `/data/point/history/challenge?date=${yearStateChange}&offset=0&limit=30`,
      )
      .then((res) => {
        for (let i = 0; i < res.data.data.months.length; i++) {
          pointMonthSum += Number(res.data.data.months[i].point);
        }
        setSumMonthPoint(
          pointMonthSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        );
        monthPoint = res.data.data.months;
        monthlyClickWeightImport();
      });
  };

  const tabMenuClick = (item: string) => {
    setActiveItem(item);
    setYearState(yearRef.current);
    setMonthState(monthRef.current);

    if (item === 'weekReward') {
      weeklyPointImport();
      navigate('/myWeight/point');
    } else if (item === 'monthReward') {
      monthlyPointImport();
      navigate('/myWeight/point?date-tab=month');
    }
  };

  return (
    <>
      <Header />
      {isMobile && (
        <div className={styles.prevWrap} onClick={() => navigate('/myWeight')}>
          <img src={prevBtn} alt={prevBtn} />
        </div>
      )}

      <div className={styles.navigation}>
        <ul>
          <li
            className={activeItem === 'weekReward' ? styles.active : ''}
            onClick={() => tabMenuClick('weekReward')}
          >
            <span>주별</span>
          </li>
          <li
            className={activeItem === 'monthReward' ? styles.active : ''}
            onClick={() => tabMenuClick('monthReward')}
          >
            <span>월별</span>
          </li>
        </ul>
      </div>
      <main className={styles.contentWrap}>
        <div
          className={`${styles.dateWrap} ${!isMobile ? styles.isDesktop : ''}`}
        >
          {tabMenuSelect === '주별' && (
            <h3>
              {Number(yearState) <= 2023 && Number(monthState) <= 9 ? (
                <img src={dateLeftNo} alt={dateLeftNo} />
              ) : (
                <img
                  onClick={() => dateWeekClick(-1)}
                  src={dateLeft}
                  alt={dateLeft}
                />
              )}
              {yearState?.toString()}년 {monthState?.toString()}월
              {Number(yearState) === yearRef.current &&
              Number(monthState) === monthRef.current ? (
                <img src={dateRightNo} alt={dateRightNo} />
              ) : (
                <img
                  onClick={() => dateWeekClick(+1)}
                  src={dateRight}
                  alt={dateRight}
                />
              )}
            </h3>
          )}
          {tabMenuSelect === '월별' && (
            <h3>
              {Number(yearState) <= 2023 ? (
                <img src={dateLeftNo} alt={dateLeftNo} />
              ) : (
                <img
                  onClick={() => dateMonthClick(-1)}
                  src={dateLeft}
                  alt={dateLeft}
                />
              )}
              {yearState?.toString()}년
              {Number(yearState) === yearRef.current ? (
                <img src={dateRightNo} alt={dateRightNo} />
              ) : (
                <img
                  onClick={() => dateMonthClick(+1)}
                  src={dateRight}
                  alt={dateRight}
                />
              )}
            </h3>
          )}
          <p>{challengePartiDay}일째 체중 기록 중이에요!</p>
        </div>
        <div
          className={`${styles.listWrap} ${
            !isMobile && tabMenuSelect === '월별'
              ? styles.isDesktop
              : !isMobile && tabMenuSelect === '주별'
              ? styles.isWeekDesktop
              : ''
          }`}
        >
          {tabMenuSelect === '주별' &&
            weeklyWeightPoint &&
            weeklyWeightPoint.map((item, idx) => (
              <div className={styles.list} key={idx}>
                {new Date(item.start_date) <= nowDate &&
                new Date(item.end_date) >= nowDate ? (
                  <div className={styles.nowTitle}>
                    <p className={styles.nowTitleP}>{idx + 1}주</p>
                  </div>
                ) : (
                  <div>
                    <p className={styles.notNowTitleP}>{idx + 1}주</p>
                  </div>
                )}
                {new Date(item.start_date) <= nowDate &&
                new Date(item.end_date) >= nowDate ? (
                  <div>
                    {item.point === 0 && <span>-</span>}
                    {item.point > 0 && (
                      <span className={styles.nowWeightSpan}>
                        {item.point
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        P
                      </span>
                    )}
                  </div>
                ) : (
                  <div>
                    {item.point === 0 && <span>-</span>}
                    {item.point > 0 && (
                      <span>
                        {item.point
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        P
                      </span>
                    )}
                  </div>
                )}
              </div>
            ))}

          {tabMenuSelect === '월별' &&
            monthlyWeightPoint &&
            monthlyWeightPoint.map((item, idx) => (
              <div
                className={`${styles.list} ${
                  !isMobile ? styles.isDesktop : styles.isMobile
                }`}
                key={idx}
              >
                {idx + 1 === monthState && yearState === yearRef.current ? (
                  <div className={styles.nowTitle}>
                    <p className={styles.nowTitleP}>{Number(item.month)}월</p>
                  </div>
                ) : (
                  <div>
                    {yearState === yearRef.current &&
                    Number(item.month) > Number(monthRef.current) ? (
                      <p
                        style={{
                          color: '#666666',
                        }}
                      >
                        {Number(item.month)}월
                      </p>
                    ) : (
                      <p>{Number(item.month)}월</p>
                    )}
                  </div>
                )}
                {idx + 1 !== monthState && (
                  <div>
                    {item.point === null && yearState !== yearRef.current && (
                      <span>- </span>
                    )}

                    {item.point === null &&
                      yearState === yearRef.current &&
                      Number(item.month) < Number(monthRef.current) && (
                        <span>- </span>
                      )}

                    {item.point === null &&
                      yearState === yearRef.current &&
                      Number(item.month) > Number(monthRef.current) && (
                        <span> </span>
                      )}

                    {item.point > 0 && (
                      <span>
                        {item.point
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        P
                      </span>
                    )}
                  </div>
                )}
                {idx + 1 === monthState && (
                  <div>
                    {item.point === null && <span>-</span>}
                    {item.point > 0 && (
                      <span className={styles.nowWeightSpan}>
                        {item.point
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        P
                      </span>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
        {isMobile && <div className={styles.mHeight}></div>}
        <div
          className={`${styles.pointWrap} ${
            !isMobile ? styles.isDesktop : styles.isMobile
          }`}
        >
          <div>
            <p>누적 포인트</p>
            {tabMenuSelect === '주별' && <p>{sumWeekPoint}P</p>}
            {tabMenuSelect === '월별' && <p>{sumMonthPoint}P</p>}
          </div>
          <div className={styles.weightBtnWrap}>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/myWeight/chart')}
            >
              <img src={chart} alt={chart} />
              <span>그래프보기</span>
            </button>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/myWeight/list')}
            >
              <img src={list} alt={list} />
              <span>리스트보기</span>
            </button>
          </div>
        </div>
      </main>
    </>
  );
}

export default WeightPoint;
