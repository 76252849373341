import styles from './ADLink.module.scss';
import content3 from '../../assets/adLink/content03.png';
import content4 from '../../assets/adLink/content04.png';
import content from '../../assets/adLink/content.png';
import close from '../../assets/adLink/close.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { omit } from 'lodash';

interface IPropsType {
  productId: number;
  productName: string;
  price: number;
  discountedPrice: number;
  closer: React.Dispatch<React.SetStateAction<boolean>>;
}
function ADLink({
  productId,
  productName,
  price,
  discountedPrice,
  closer,
}: IPropsType) {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div
          className={styles.content}
          onClick={() =>
            (window.location.href = `https://connectdoctor.co.kr/productDetail/${productId}`)
          }
        >
          {/* {productName.replace(/ /g, '').includes('청연채') && (
            <img src={content} alt="상품" />
          )} */}
          <img src={content} alt="상품" />
        </div>
        <button
          className={styles.detailBtn}
          onClick={() =>
            (window.location.href = `https://connectdoctor.co.kr/productDetail/${productId}`)
          }
        ></button>
        <button className={styles.closeBtn} onClick={() => closer(false)}>
          <img src={close} alt="닫기" />
        </button>
      </div>
    </div>
  );
}
export default ADLink;
