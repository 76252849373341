import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import styles from './OrderDetail.module.scss';
import leftArrow from '../../assets/leftArrow02.png';
import imsiProduct from '../../assets/imsi/imsiProduct4.png';
import discountListMark from '../../assets/discountMark.png';
import deliveryDirect from '../../assets/deliveryDirect.png';
import noDirect from '../../assets/noDirect.png';
import prevNavi from '../../assets/prevNavi.png';
import refundDirect from '../../assets/refundDirect.png';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { IOrderDataType } from '../OrderComplete/OrderComplete';
import LoginHandler from '../../components/LoginHandler/LoginHandler';
import { WEEKDAY } from '../Questionnaire/Questionnaire';
import rightArrow from '../../assets/rightArrow.png';
import { getPayMethodLabel } from '../../common/getPaymethod';
import { isMobile } from 'react-device-detect';

function OrderDetail() {
  const existPayMethod: string | string[] = [];
  const { orderNumber } = useParams();
  const navigate = useNavigate();
  const [orderInfoData, setOrderInfoData] = useState<any[]>([]);
  const [orderData, setOrderData] = useState<IOrderDataType[]>();
  const [productIdList, setProductIdList] = useState<number[]>([]);
  const [telemedicineList, setTelemedicineList] = useState<any[]>([]);
  const [reservationDate, setReservationDate] = useState('');
  // 진료 완료 아닌 상태만 문진표 수정 가능하도록
  const [isQuesChange, setIsQuesChange] = useState(false);
  // 아래 주문상태인 경우만 배송지 변경 가능하도록
  const [isDeliveryChange, setIsDeliveryChange] = useState(false);
  // 주문취소상태 + 가상계좌 하나라도 있는 경우
  const [isRefund, setIsRefund] = useState(false);
  // 환불 계좌 등록되었는지
  const [hasRefund, setHasRefund] = useState(false);
  // 환불 계좌 등록되고 가상계좌가 2개인 경우
  const [hasRefund2, setHasRefund2] = useState(false);

  useEffect(() => {
    axiosClient.get(`/data/order?order_id=${orderNumber}`).then((res) => {
      if (!res.data.data.length) {
        alert('주문번호가 올바르지 않거나 권한이 없습니다.');
        navigate('/');
      }
      setOrderData(res.data.data);
      setOrderInfoData(res.data.data[1]);

      // 진료 완료 여부 Y: 완료 -> 문진표변경x N: 미완료
      if (res.data.data[0].is_prescription_complete === 'Y')
        setIsQuesChange(false);
      else setIsQuesChange(true);

      // 주소지 변경 여부 Y: 가능 N: 불가능
      if (res.data.data[0].can_change_address === 'Y')
        setIsDeliveryChange(true);
      else setIsDeliveryChange(false);
    });
  }, []);

  useEffect(() => {
    if (!orderData || !orderInfoData) return;

    if (orderData.length > 0 && orderData[0].pay_status === '주문취소') {
      const hasVirtual = orderInfoData.some(
        (item) => item.paymethod === 'virtual',
      );
      const allVirtual = orderInfoData.every(
        (item) => item.paymethod === 'virtual',
      );

      if (hasVirtual) setIsRefund(true);
      else setIsRefund(false);

      if (orderData[0].vbank_refund_use === 'Y') setHasRefund(true);
      else setHasRefund(false);

      if (allVirtual && orderData[0].vbank_refund_use === 'Y')
        setHasRefund2(true);
      else setHasRefund2(false);
    }
  }, [orderData, orderInfoData]);

  const getVirtualPadding = () => {
    return (
      orderInfoData &&
      orderInfoData.some((info) => info.paymethod === 'virtual')
    );
  };

  useEffect(() => {
    if (!orderData) return;
    let list: number[] = [];
    let teleList: any[] = [];
    orderData[0].products?.map((product) => {
      if (!reservationDate && product.is_reservation == 'Y')
        setReservationDate(product.reservation_at!);
      if (!list.includes(product.product_id)) list.push(product.product_id);
      if (product.is_reservation && !teleList.includes(product.product_id))
        teleList.push(product.product_id);
    });
    setProductIdList(list);
    setTelemedicineList(teleList);
  }, [orderData]);
  function handleOrderCancel() {
    let confirm = window.confirm('주문건을 취소하시겠습니까?');
    if (!confirm) return;
    axiosClient
      .get(`/func/order/order_cancel?order_id=${orderNumber}`)
      .then((res) => window.location.reload())
      .catch((err) => {
        if (
          err.response.data.detail ===
          '가상계좌 결제건은 관리자에게 문의해주세요.'
        )
          alert(
            '가상계좌 결제가 포함된 결제건 취소를 원할 시, 채널톡 상담을 통해 결제건 취소를 요청해주세요.',
          );
        else alert('에러가 발생했습니다. 고객센터로 문의 바랍니다.');
      });
  }
  function deliveryCheck() {
    if (!orderData) return;
    window.open(
      `/deliveryDetail/${orderData[0].products[0].send_company}/${orderData[0].products[0].send_number}`,
      '',
      'width=500,height=700',
    );
  }
  function copyAddress() {
    navigator.clipboard
      .writeText('서울 강남구 테헤란로64길 8 지하1층')
      .then((res) => alert('주소가 복사되었습니다.'));
  }
  const [paymethodLabel, setPaymethodLabel] = useState('');
  // useEffect(() => {
  //   if (!orderData) return;

  //   if (orderData[0].order_paymethod === 'card') setPaymethodLabel('신용카드');
  //   else if (orderData[0].order_paymethod === 'iche')
  //     setPaymethodLabel('계좌이체');
  //   else if (orderData[0].order_paymethod === 'point')
  //     setPaymethodLabel('포인트');
  //   else if (orderData[0].order_paymethod === 'hpp')
  //     setPaymethodLabel('휴대폰결제');
  //   else if (orderData[0].order_paymethod === 'payco')
  //     setPaymethodLabel('페이코');
  //   else if (orderData[0].order_paymethod === 'payple')
  //     setPaymethodLabel('카드');
  //   else if (orderData[0].order_paymethod === 'naverpay')
  //     setPaymethodLabel('네이버페이');
  //   else if (orderData[0].order_paymethod === 'kakaopay')
  //     setPaymethodLabel('카카오페이');
  //   else if (orderData[0].order_paymethod === 'wechat')
  //     setPaymethodLabel('위챗');
  //   else if (orderData[0].order_paymethod === 'applepay')
  //     setPaymethodLabel('애플페이');
  //   else if (orderData[0].order_paymethod === 'smilepay')
  //     setPaymethodLabel('스마일페이');
  //   else if (orderData[0].order_paymethod === 'paypal')
  //     setPaymethodLabel('페이팔');
  // }, [orderData]);

  return (
    <div className={`${styles.container} ${isMobile ? styles.isMobile : ''}`}>
      <LoginHandler />
      <Header />
      <div className={styles.header}>
        {isMobile && (
          <img onClick={() => navigate(-1)} src={prevNavi} alt="prev" />
        )}
        <span>주문상세</span>
        {isMobile && <div></div>}
      </div>
      <div className={styles.orderInfoWrap}>
        <div className={styles.orderHeader}>
          <span>
            {orderData &&
              orderData[0].order_date
                ?.substring(0, 10)
                .replace(/-/gi, '.')}{' '}
            주문
          </span>
          <span>주문번호 : {orderData && orderData[0].order_id}</span>
        </div>
        {orderData &&
          productIdList.map((id, idx) => {
            let optionList: string[] = [];
            let count = 0;
            let productImage = '';
            let productName = '';
            let orderSendNumber = '';
            let sendCompany = '';

            orderData[0].products
              .filter((product) => product.product_id == id)
              .map((product) => {
                optionList.push(product.option);
                count += product.count;
                productImage = product.image;
                productName = product.title;
                orderSendNumber = product.send_number || '미등록';
                sendCompany = product.send_company || '';
              });
            return (
              <div className={styles.orderProductInfoWrap} key={id}>
                <div className={styles.sectionHeader}>
                  <span>주문상품정보</span>
                  {(orderData[0].pay_status == '배송대기' ||
                    orderData[0].pay_status == '결제완료') && (
                    <div
                      className={styles.orderCancelBtn}
                      onClick={handleOrderCancel}
                    >
                      주문 취소하기
                    </div>
                  )}
                  {!(
                    orderData[0].pay_status == '결제대기' ||
                    orderData[0].pay_status == '배송대기' ||
                    orderData[0].pay_status == '결제완료'
                  ) &&
                    orderData[0].products[0].send_number && (
                      <div
                        className={styles.deliveryCheck}
                        onClick={deliveryCheck}
                      >
                        배송조회
                      </div>
                    )}
                  <div
                    className={styles.status}
                    style={{
                      borderColor:
                        orderData[0].pay_status == '배송대기'
                          ? '#F6F7F9'
                          : orderData[0].pay_status == '배송준비'
                          ? '#00BE35'
                          : orderData[0].pay_status == '배송중'
                          ? '#B7E7BD'
                          : orderData[0].pay_status == '배송완료'
                          ? '#121842'
                          : orderData[0].pay_status == '주문취소'
                          ? '#FF3D3D'
                          : orderData[0].pay_status == '결제대기'
                          ? '#3259E6'
                          : orderData[0].pay_status == '결제완료'
                          ? '#131C31'
                          : '',
                      color:
                        orderData[0].pay_status == '배송대기'
                          ? '#999999'
                          : orderData[0].pay_status == '배송준비'
                          ? '#00BE35'
                          : orderData[0].pay_status == '배송중'
                          ? '#00BE35'
                          : orderData[0].pay_status == '배송완료'
                          ? '#141736'
                          : orderData[0].pay_status == '주문취소'
                          ? '#FF3D3D'
                          : orderData[0].pay_status == '결제대기'
                          ? '#fff'
                          : orderData[0].pay_status == '결제완료'
                          ? '#fff'
                          : '',
                      backgroundColor:
                        orderData[0].pay_status == '배송대기'
                          ? '#F6F7F9'
                          : orderData[0].pay_status == '결제대기'
                          ? '#3259E6'
                          : orderData[0].pay_status == '결제완료'
                          ? '#131C31'
                          : 'white',
                    }}
                  >
                    {orderData[0].pay_status}
                  </div>
                </div>
                <div className={styles.productWrap}>
                  <div className={styles.thumbnailWrap}>
                    <img
                      src={orderData[0].products[idx].image}
                      alt="thumbnail"
                    />
                  </div>
                  <div className={styles.right}>
                    <span className={styles.productName}>{productName}</span>
                    <div className={styles.option}>
                      <span>옵션</span>
                      <ul>
                        {optionList.map((option) => (
                          <li key={option}>{option}</li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.count}>
                      <span>수량</span>
                      <span>{count}개</span>
                    </div>
                    <div className={styles.deliveryNumber}>
                      <span>운송장번호</span>
                      <span>
                        {orderSendNumber}
                        {sendCompany && <> ({sendCompany})</>}
                      </span>
                    </div>
                  </div>
                </div>
                {telemedicineList.length - 1 === idx && (
                  <div
                    className={`${styles.quesWrap} ${
                      isQuesChange ? '' : styles.noClick
                    }`}
                  >
                    <div
                      className={styles.quesBtnWrap}
                      onClick={() => {
                        if (!isQuesChange) return;
                        if (orderData && orderData[0].order_id)
                          navigate(
                            `/questionnaire-edit/${orderData[0].order_id}`,
                          );
                      }}
                    >
                      <span>문진표 확인 및 변경</span>
                      <img
                        src={isQuesChange ? deliveryDirect : noDirect}
                        alt="direct"
                      />
                    </div>
                    <div className={styles.quesAlertWrap}>
                      <span>
                        문진표 수정은 의사와{isMobile && <br />} 진료 전까지
                        가능합니다.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {/* <div className={styles.refundWrap}>
        <div className={styles.refundHeader}>교환/반품/환불 사유</div>
        <p>제품 하자로 인한 환불</p>
      </div> */}
      {reservationDate && (
        <div className={styles.reservationInfoWrap}>
          <div className={styles.reservationHeader}>방문 예약 정보</div>
          <div className={styles.reservationInfo}>
            <div>
              <span>예약병원</span>
              <span>
                마디로 한의원 선릉점
                <div
                  className={styles.mapBtn}
                  onClick={() => {
                    window.open(
                      'https://map.naver.com/v5/search/%EB%A7%88%EB%94%94%EB%A1%9C%ED%95%9C%EC%9D%98%EC%9B%90/place/1036931386?c=15,0,0,0,dh&placePath=%3Fentry%253Dbmp',
                      '_blank',
                    );
                  }}
                >
                  <span>지도보기</span>
                  <img src={rightArrow} alt="화살표" />
                </div>
              </span>
            </div>
            <div>
              <span>병원주소</span>
              <span>
                서울 강남구 테헤란로64길 8 지하1층{' '}
                <div className={styles.copyBtn} onClick={copyAddress}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M11.9583 4.66675V11.0834H3.79163V1.16675H8.74996M11.9583 4.66675H8.74996V1.16675M11.9583 4.66675L8.74996 1.16675"
                      stroke="#FF5656"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.04163 5.8335V12.8335H8.16663"
                      stroke="#FF5656"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.54163 5.8335H6.70829M5.54163 8.16683H9.04163"
                      stroke="#FF5656"
                      strokeLinecap="square"
                    />
                  </svg>
                  <span>복사</span>
                </div>
              </span>
            </div>
            <div>
              <span>예약날짜</span>
              <span>{`${reservationDate.slice(0, 10).replace(/-/gi, '.')}(${
                WEEKDAY[new Date(reservationDate).getDay()]
              }) / ${reservationDate.slice(11, 16)}`}</span>
            </div>
          </div>
        </div>
      )}
      {orderData &&
        orderData[0] &&
        orderData[0].pickup == 'Y' &&
        !reservationDate && (
          <div className={styles.reservationInfoWrap}>
            <div className={styles.reservationHeader}>방문 수령 정보</div>
            <div className={styles.reservationInfo}>
              <div>
                <span>수령병원</span>
                <span>
                  마디로 한의원 선릉점
                  <div
                    className={styles.mapBtn}
                    onClick={() => {
                      window.open(
                        'https://map.naver.com/v5/search/%EB%A7%88%EB%94%94%EB%A1%9C%ED%95%9C%EC%9D%98%EC%9B%90/place/1036931386?c=15,0,0,0,dh&placePath=%3Fentry%253Dbmp',
                        '_blank',
                      );
                    }}
                  >
                    <span>지도보기</span>
                    <img src={rightArrow} alt="화살표" />
                  </div>
                </span>
              </div>
              <div>
                <span>병원주소</span>
                <span>
                  서울 강남구 테헤란로64길 8 지하1층{' '}
                  <div className={styles.copyBtn} onClick={copyAddress}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.9583 4.66675V11.0834H3.79163V1.16675H8.74996M11.9583 4.66675H8.74996V1.16675M11.9583 4.66675L8.74996 1.16675"
                        stroke="#FF5656"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.04163 5.8335V12.8335H8.16663"
                        stroke="#FF5656"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.54163 5.8335H6.70829M5.54163 8.16683H9.04163"
                        stroke="#FF5656"
                        strokeLinecap="square"
                      />
                    </svg>
                    <span>복사</span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        )}
      <div className={styles.addressInfoWrap}>
        <div className={styles.addressHeader}>배송지 정보</div>
        <div className={styles.addressInfo}>
          <div>
            <span>수령인</span>
            <span>{orderData && orderData[0].receive_name}</span>
          </div>
          <div>
            <span>휴대폰</span>
            <span>{orderData && orderData[0].receive_phone}</span>
          </div>
          <div>
            <span>주소지</span>
            <span>{orderData && orderData[0].receive_address}</span>
          </div>
          <div>
            <span>배송메모</span>
            <span>{orderData && orderData[0].request_memo}</span>
          </div>
          <div
            className={`${styles.deliveryChangeWrap} ${
              isDeliveryChange ? '' : styles.noClick
            }`}
          >
            <div
              className={styles.deliveryChangeBtnWrap}
              onClick={() => {
                if (!isDeliveryChange) return;
                if (orderData && orderData[0].order_id)
                  navigate(`/address-edit/${orderData[0].order_id}`);
              }}
            >
              <span>배송정보 변경</span>
              <img
                src={isDeliveryChange ? deliveryDirect : noDirect}
                alt="direct"
              />
            </div>
            <div className={styles.deliveryAlertWrap}>
              <span>
                배송정보 변경은 배송{isMobile && <br />}시작 전까지 가능합니다.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.payInfoWrap}>
        <div className={styles.payHeader}>결제정보</div>
        <div className={styles.priceCalculation}>
          <div>
            <span>주문 금액</span>
            <span>{orderData && orderData[0].price?.toLocaleString()}원</span>
          </div>
          <div>
            <span>배송비</span>
            <span>
              {orderData && orderData[0].delivery_price?.toLocaleString()}원
            </span>
          </div>
          <div>
            <span>할인금액</span>
            <span>
              -
              {orderData &&
                (
                  orderData[0].use_coupon +
                  orderData[0].use_point +
                  orderData[0].use_promotion
                )?.toLocaleString()}
              원
            </span>
          </div>
          <ul className={styles.discountInfoList}>
            {orderData && orderData[0].use_coupon > 0 && (
              <li>
                <div>
                  <img src={discountListMark} alt="discountListMark" />
                  <span>쿠폰 사용</span>
                </div>
                <span>-{orderData[0].use_coupon?.toLocaleString()}원</span>
              </li>
            )}
            {orderData && orderData[0].use_promotion > 0 && (
              <li>
                <div>
                  <img src={discountListMark} alt="discountListMark" />
                  <span>프로모션코드 사용</span>
                </div>
                <span>-{orderData[0].use_promotion?.toLocaleString()}원</span>
              </li>
            )}{' '}
            {orderData && orderData[0].use_point > 0 && (
              <li>
                <div>
                  <img src={discountListMark} alt="discountListMark" />
                  <span>포인트 사용</span>
                </div>
                <span>-{orderData[0].use_point?.toLocaleString()}원</span>
              </li>
            )}
          </ul>
        </div>
        <div className={styles.totalPrice}>
          <div>
            <span>최종 결제 금액</span>
            <span>
              {orderData && orderData[0].total_price?.toLocaleString()}원
            </span>
          </div>
          <span>결제 금액에서 할인/환불 금액을 뺀 금액입니다.</span>
        </div>
      </div>
      <div className={styles.payMethodWrap}>
        <div className={styles.payMethodHeader}>
          <span>결제 수단</span>
          {isRefund && !hasRefund && (
            <div
              className={styles.refundBtnWrap}
              onClick={() => {
                if (orderData && orderData[0].order_id)
                  navigate(`/refund-edit/${orderData[0].order_id}`, {
                    state: {
                      isNavi: 'Y',
                    },
                  });
              }}
            >
              <span>환불 계좌 등록</span>
              <img src={refundDirect} />
            </div>
          )}
        </div>
        <>
          {orderData && orderData[0].bank_account ? (
            <div className={styles.payMethodListWrap}>
              {orderInfoData &&
                orderInfoData.map((info, idx) => {
                  if (info.paymethod === 'virtual') {
                    const isExistVirtual = existPayMethod.includes(
                      info.paymethod,
                    );
                    if (!isExistVirtual && hasRefund) {
                      existPayMethod.push(info.paymethod);
                    }

                    if (!isExistVirtual) {
                      return (
                        <div key={idx} className={styles.paymethodFrame}>
                          <div
                            className={`${styles.labelWrap} ${
                              hasRefund ? styles.refundLabel : ''
                            }`}
                          >
                            {hasRefund ? '환불계좌' : '가상계좌'}
                          </div>
                          <div className={styles.contentWrap}>
                            <div className={styles.leftWrap}>
                              <span>
                                {hasRefund
                                  ? `[${orderData[0].vbank_refund_bank}]${orderData[0].vbank_refund_no}`
                                  : info.vbank &&
                                    `[${info.vbank}]${info.vbankno}`}
                              </span>
                              {!hasRefund && (
                                <span>
                                  {info.vbank_date &&
                                    `${info.vbank_date
                                      ?.substring(0, 10)
                                      .replace(/-/gi, '.')}까지`}
                                </span>
                              )}
                            </div>
                            <div className={styles.rightWrap}>
                              {hasRefund2 ? (
                                <span>
                                  {orderData &&
                                    orderData[0].total_price?.toLocaleString()}
                                  원
                                </span>
                              ) : hasRefund ? (
                                <span>{`${Number(
                                  info.price,
                                ).toLocaleString()}원`}</span>
                              ) : (
                                <span>{`${Number(
                                  info.price,
                                ).toLocaleString()}원`}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    } else return null;
                  } else {
                    const payMethodLabel = getPayMethodLabel(info.paymethod);
                    return (
                      <div key={idx} className={styles.paymethodFrame}>
                        <div className={styles.labelWrap}>{payMethodLabel}</div>
                        <div className={styles.contentWrap}>
                          <div className={styles.leftWrap}></div>
                          <div className={styles.rightWrap}>
                            <span>{`${Number(
                              info.price,
                            ).toLocaleString()}원`}</span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          ) : (
            orderData &&
            !orderData[0].bank_account && (
              <div className={styles.payMethodListWrap}>
                {orderInfoData &&
                  orderInfoData.map((info, idx) => {
                    if (info.paymethod === 'virtual') {
                      const isExistVirtual = existPayMethod.includes(
                        info.paymethod,
                      );
                      if (!isExistVirtual && hasRefund) {
                        existPayMethod.push(info.paymethod);
                      }

                      if (!isExistVirtual) {
                        return (
                          <div key={idx} className={styles.paymethodFrame}>
                            <div
                              className={`${styles.labelWrap} ${
                                hasRefund ? styles.refundLabel : ''
                              }`}
                            >
                              {hasRefund ? '환불계좌' : '가상계좌'}
                            </div>
                            <div className={styles.contentWrap}>
                              <div className={styles.leftWrap}>
                                <span>
                                  {hasRefund
                                    ? `[${orderData[0].vbank_refund_bank}]${orderData[0].vbank_refund_no}`
                                    : info.vbank &&
                                      `[${info.vbank}]${info.vbankno}`}
                                </span>
                                {!hasRefund && (
                                  <span>
                                    {info.vbank_date &&
                                      `${info.vbank_date
                                        ?.substring(0, 10)
                                        .replace(/-/gi, '.')}까지`}
                                  </span>
                                )}
                              </div>
                              <div className={styles.rightWrap}>
                                {hasRefund2 ? (
                                  <span>
                                    {orderData &&
                                      orderData[0].total_price?.toLocaleString()}
                                    원
                                  </span>
                                ) : hasRefund ? (
                                  <span>{`${Number(
                                    info.price,
                                  ).toLocaleString()}원`}</span>
                                ) : (
                                  <span>{`${Number(
                                    info.price,
                                  ).toLocaleString()}원`}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      } else return null;
                    } else {
                      const payMethodLabel = getPayMethodLabel(info.paymethod);
                      return (
                        <div key={idx} className={styles.paymethodFrame}>
                          <div className={styles.labelWrap}>
                            {payMethodLabel}
                          </div>
                          <div className={styles.contentWrap}>
                            <div className={styles.leftWrap}></div>
                            <div className={styles.rightWrap}>
                              <span>{`${Number(
                                info.price,
                              ).toLocaleString()}원`}</span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            )
          )}
        </>
      </div>
      <div className={styles.refundGuide}>
        <span>모든 환불건은 최초 결제 수단으로 환불됩니다.</span>
      </div>
      <Footer />
    </div>
  );
}
export default OrderDetail;
