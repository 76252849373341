import styles from './RefundEdit.module.scss';
import { isMobile } from 'react-device-detect';
import Header from '../../components/Header/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import prevNavi from '../../assets/prevNavi.png';
import notice from '../../assets/notice.png';
import { useForm } from 'react-hook-form';
import axiosClient from '../../libs/axiosClient';
import { useEffect } from 'react';

function RefundEdit() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    setError,
  } = useForm();

  useEffect(() => {
    const naviState = location.state;
    if (!naviState) return navigate('/');
  }, []);

  const onSubmit = () => {
    if (!orderId) return;
    let formData = new FormData();
    formData.append('order_id', orderId);
    formData.append('bank', watch().refundBank);
    formData.append('bank_no', watch().refundNum);
    formData.append('bank_name', watch().refundName);

    axiosClient
      .post('/func/order/refund_account', formData)
      .then((res) => {
        alert('환불 계좌가 정상적으로 등록되었습니다.');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmitError = (error: any) => {
    let errorList: { message: string; ref: HTMLElement }[] =
      Object.values(error);
    window.scrollTo(
      0,
      window.scrollY + errorList[0].ref.getBoundingClientRect().top - 150,
    );
    alert(errorList[0].message);
  };

  return (
    <div className={`${styles.container} ${isMobile ? styles.isMobile : ''}`}>
      <Header />
      <div className={styles.contentWrap}>
        <div className={styles.header}>
          {isMobile && (
            <img onClick={() => navigate(-1)} src={prevNavi} alt="prev" />
          )}
          <span>환불 계좌 등록</span>
          {isMobile && <div></div>}
        </div>
        <div className={styles.mainWrap}>
          <div className={styles.refundWrap}>
            <div>
              <div className={styles.titleWrap}>
                <span>*</span>
                <span>은행명</span>
              </div>
              <div
                className={`${styles.inputWrap} ${
                  errors.refundBank ? styles.validationError : ''
                }`}
              >
                <input
                  type="text"
                  {...register('refundBank', {
                    value: '',
                    required: {
                      value: true,
                      message: '은행명을 입력해 주세요.',
                    },
                  })}
                />
              </div>
            </div>
            <div>
              <div className={styles.titleWrap}>
                <span>*</span>
                <span>계좌번호</span>
              </div>
              <div
                className={`${styles.inputWrap} ${
                  errors.refundNum ? styles.validationError : ''
                }`}
              >
                <input
                  type="text"
                  {...register('refundNum', {
                    value: '',
                    required: {
                      value: true,
                      message: '계좌번호를 입력해 주세요.',
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: '계좌번호는 숫자만 입력 가능합니다.',
                    },
                    onChange: (e) => {
                      let value = e.currentTarget.value;
                      if (isNaN(Number(e.currentTarget.value)))
                        setValue('refundNum', value.slice(0, value.length - 1));
                    },
                  })}
                />
              </div>
            </div>
            <div>
              <div className={styles.titleWrap}>
                <span>*</span>
                <span>예금주명</span>
              </div>
              <div
                className={`${styles.inputWrap} ${
                  errors.refundName ? styles.validationError : ''
                }`}
              >
                <input
                  type="text"
                  {...register('refundName', {
                    value: '',
                    required: {
                      value: true,
                      message: '예금주명을 입력해 주세요.',
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className={styles.noticeWrap}>
            <img src={notice} alt="notice" />
            <div className={styles.hitText}>
              <span>
                입력한 정보가 정확한지 다시 한 번 확인해주세요. 계좌 정보 등록
                후에는
              </span>
              <span className={styles.hit}> 수정할 수 없습니다.</span>
            </div>
            <div className={styles.greyText}>
              <span>
                환불 계좌 등록 후,
                <br /> 채널톡을 통해 주문 취소 및 가상 계좌 결제 건에 대한
                환불을 요청해 주세요.
                <br /> 보다 더 빠르게 처리해 드리겠습니다.
              </span>
            </div>
          </div>
        </div>
        <div className={styles.editBtnWrap}>
          <div
            className={styles.editBtn}
            onClick={handleSubmit(onSubmit, onSubmitError)}
          >
            <span>등록하기</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundEdit;
