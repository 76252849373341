import styles from './ADBanner.module.scss';
import product from '../../assets/adLink/product.png';
import { useNavigate } from 'react-router-dom';
import { isDesktop } from 'react-device-detect';
import { useEffect, useState } from 'react';
interface IPropsType {
  endDate: string;
  productId: number;
  productName: string;
  price: number;
  discountedPrice: number;
  top?: number;
}
function ADBanner({
  endDate,
  productId,
  productName,
  price,
  discountedPrice,
  top,
}: IPropsType) {
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);
  useEffect(() => {
    // setTimeout(() => {
    //   if (idx == 0) setIdx(1);
    //   else if (idx == 1) setIdx(0);
    // }, 3000);
  }, [idx]);

  return (
    <>
      <div className={styles.heighter}></div>
      {idx == 0 && (
        <div
          className={styles.container}
          onClick={() =>
            (window.location.href = `https://connectdoctor.co.kr/productDetail/${productId}`)
          }
        >
          <div className={styles.productImageWrap}>
            <img src={product} alt="상품썸네일" />
          </div>
          {/* <h1 className={styles.productName}>청연채</h1> */}
          <div className={styles.priceWrap}>
            <div className={styles.price}>
              <span>정상가</span>
              <span> : </span>
              <span>{'229,000'}원</span>
            </div>
            <div className={styles.discountedPrice}>
              <span>할인가</span>
              <span> : </span>
              <span>{discountedPrice.toLocaleString()}원</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ADBanner;
