import styles from './Coupon.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { ICouponType } from '../Order/Order';
import MyPageNavigate from '../../components/MyPageNavigate/MyPageNavigate';
import { isDesktop } from 'react-device-detect';
import leftArrow from '../../assets/leftArrow02.png';
import LoginHandler from '../../components/LoginHandler/LoginHandler';

function Coupon() {
  const navigate = useNavigate();
  const [couponList, setCouponList] = useState<ICouponType[]>([]);
  useEffect(() => {
    axiosClient
      .get('/data/coupon')
      .then((res) => setCouponList(res.data.data.coupons));
  }, []);
  function dateFormatter(expireDate: string) {
    let date = new Date(expireDate);
    return (
      date.getFullYear() +
      '년 ' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '월 ' +
      ('0' + date.getDate()).slice(-2) +
      '일 '
    );
  }
  return (
    <div>
      <LoginHandler />
      <Header />
      <div className={styles.container}>
        {isDesktop && <MyPageNavigate />}
        <h3 className={styles.title}>
          {!isDesktop && (
            <img src={leftArrow} alt="leftArrow" onClick={() => navigate(-1)} />
          )}
          <span>쿠폰</span>
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            alert('유효하지 않은 쿠폰번호 입니다.');
          }}
          className={styles.addCouponForm}
        >
          <label>
            <span>쿠폰등록</span>
            <input type="text" placeholder="쿠폰번호를 입력해주세요." />
          </label>
          <button>등록</button>
        </form>
        <div className={styles.usableCouponListWrap}>
          <h4>
            사용가능 쿠폰{' '}
            <span>
              {
                couponList.filter(
                  (coupon) =>
                    coupon.status == 'N' &&
                    new Date(coupon.expire_date) >= new Date(),
                ).length
              }
            </span>{' '}
            장
          </h4>
          <ul className={styles.usableCouponList}>
            {couponList
              .filter(
                (coupon) =>
                  coupon.status == 'N' &&
                  new Date(coupon.expire_date) >= new Date(),
              )
              .map((coupon) => (
                <li key={coupon.id}>
                  <span>{coupon.coupon_name}</span>
                  <span>∙{<>{dateFormatter(coupon.expire_date)}</>}까지</span>
                </li>
              ))}
          </ul>
        </div>
        <div className={styles.unusableCouponListWrap}>
          <h4>
            사용만료{' '}
            <span>
              {
                couponList.filter(
                  (coupon) =>
                    coupon.status == 'E' ||
                    new Date(coupon.expire_date) < new Date(),
                ).length
              }
            </span>{' '}
            장
          </h4>
          <ul className={styles.unusableCouponList}>
            {couponList
              .filter(
                (coupon) =>
                  coupon.status == 'E' ||
                  new Date(coupon.expire_date) < new Date(),
              )
              .map((coupon) => (
                <li key={coupon.id}>
                  <span>{coupon.coupon_name}</span>
                  <span>∙{<>{dateFormatter(coupon.expire_date)}</>}까지</span>
                </li>
              ))}
          </ul>
        </div>
        <div className={styles.couponGuide}>
          <h4>쿠폰 사용 안내</h4>
          <ul>
            <li>
              · 할인쿠폰의 할인금액이 상품의 판매가를 초과할 경우 사용이
              불가능합니다.
            </li>
            <li>
              · 할인쿠폰은 중복으로 사용할 수 없으며, 1회 결제시 1개의
              할인쿠폰만 사용 가능합니다.
            </li>
            <li>
              · 주문취소 및 환불시 이미 사용한 쿠폰은 재발급되지 않습니다.
            </li>
            <li>
              · 발급된 할인쿠폰의 유효기간은 조기종료 및 변경 될 수 있습니다.
            </li>
            <li>
              · 유효기간이 지난 할인쿠폰은 자동 소멸하오니 기간 만료 전에 꼭
              사용하시기 바랍니다.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Coupon;
