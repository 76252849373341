import styles from './MainReview.module.scss';
import rightArrow from '../../assets/rightArrow.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import MainReviewFrame from '../MainReviewFrame/MainReviewFrame';
import { loginCheck } from '../../auth/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IReviewDataType } from '../../pages/MyReview/MyReview';
import axiosClient from '../../libs/axiosClient';
import ReviewDetailModal from '../ReviewDetailModal/ReviewDetailModal';
import { useQuery } from 'react-query';
function MainReview() {
  const navigate = useNavigate();
  // const [reviewData, setReviewData] = useState<IReviewDataType[]>([]);
  const [reviewDetailData, setReviewDetailData] = useState<IReviewDataType>();
  const [reviewDetailOpen, setReviewDetailOpen] = useState(false);
  const { data: reviewData } = useQuery<IReviewDataType[]>(
    ['main', 'photo_review'],
    () =>
      axiosClient
        .get(
          '/data/product_talk/photo-review-list?&talk_type=상품평가&limit=5&order_by=star',
        )
        .then((res) => res.data.data),
    {
      staleTime: 60000 * 60,
    },
  );
  // useEffect(() => {
  //   axiosClient
  //     .get('/data/product_talk/photo-review-list?&talk_type=상품평가&limit=5')
  //     .then((res) => setReviewData(res.data.data));
  // }, []);
  return (
    <div className={styles.container}>
      <h3 onClick={() => navigate('/review/all')}>
        <span>실시간 리뷰</span>
        <img src={rightArrow} alt="rightArrow" />
      </h3>
      <span>최신 고객 리뷰를 지금 바로 확인해 보세요.</span>
      <div className={styles.reviewSliderWrap}>
        <Swiper
          slidesPerView={2.7}
          spaceBetween={10}
          className={styles.reviewSlider}
        >
          {reviewData?.map((review) => (
            <SwiperSlide
              key={review.id}
              onClick={() => {
                setReviewDetailOpen(true);
                setReviewDetailData(review);
              }}
            >
              <MainReviewFrame review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
        {!loginCheck() && (
          <div className={styles.reviewFilter}>
            <p>
              의료법에 의거하여 의약품 후기는
              <br />
              로그인 후 보실 수 있습니다.
            </p>
            <Link to="/login">로그인</Link>
          </div>
        )}
      </div>
      {reviewDetailOpen && (
        <ReviewDetailModal
          closer={setReviewDetailOpen}
          imageList={reviewDetailData!.images}
          userId={reviewDetailData!.member_id}
          age={reviewDetailData!.age || 0}
          gender={reviewDetailData!.gender}
          weight={reviewDetailData!.weight}
          score={
            reviewDetailData!.stars0 +
              reviewDetailData!.stars1 +
              reviewDetailData!.stars2 +
              reviewDetailData!.stars3 || 0
          }
          content={reviewDetailData!.content}
          productImage={reviewDetailData!.product.list_image}
        />
      )}
    </div>
  );
}
export default MainReview;
