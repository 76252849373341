import styles from './Footer.module.scss';
import logo from '../../assets/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import bottomArrow from '../../assets/bottomArrow04.png';
function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  function handleBusinessInfoOPen() {
    window.open(
      'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8768602786&apv_perm_no=',
      '',
      'width=750,height=700',
    );
  }
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={styles.opener} onClick={() => setIsOpen((prev) => !prev)}>
        <img
          src={bottomArrow}
          style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0)' }}
        />
      </div>
      {isOpen && (
        <div
          className={`${styles.container} ${isMobile ? styles.isMobile : ''}`}
        >
          <div style={{ maxWidth: '850px', margin: '0 auto' }}>
            <div className={styles.companyInfoWrap}>
              <div className={styles.companyInfoTop}>
                <p className={styles.companyPhone}>고객센터 070-4138-0176</p>
                <p className={styles.companyOpenTime}>
                  운영시간 : 평일 10시 - 18시 / 점심시간 11시 30분 - 12시 30분
                </p>
              </div>
              {/* <div className={styles.companyInfoBorder}></div> */}
              <div className={styles.companyInfoBottom}>
                <div className={styles.companyName}>
                  <p>(주) 메디커넥트</p>
                </div>
                <div className={styles.companyInfoBottomContent}>
                  <div className={styles.companyInfoLine}>
                    <span>대표 : 노승원대표</span>
                  </div>
                  <div className={styles.companyInfoLine}>
                    <span>사업자 등록번호 : 876-86-02786</span>
                    <span
                      className={styles.businessInfo}
                      onClick={handleBusinessInfoOPen}
                    >
                      [사업자 정보확인]
                    </span>
                  </div>
                  <div className={styles.companyInfoLine}>
                    <span>통신판매업 신고번호 : 제2024-서울강남-02391호</span>
                  </div>
                  <div className={styles.companyInfoLine}>
                    <span>
                      주소 : 서울특별시 강남구 테헤란로70길 12, 402-에이동
                      1702호(대치동, H 타워)
                    </span>
                  </div>
                  <div className={styles.companyInfoLine}>
                    <span>
                      개인정보 처리 책임자 : 노승원 (connectdoctor.kr@gmail.com)
                    </span>
                  </div>
                </div>
                <span className={styles.copyright}>
                  Copyright(c) connectdoctor.co.kr. All Rights Reserved.
                </span>
              </div>
              <ul className={styles.navigation}>
                <li onClick={() => navigate('/companyInfo')}>회사소개</li>
                <li onClick={() => navigate('/terms')}>이용약관</li>
                <li onClick={() => navigate('/guide')}>이용안내</li>
                <li onClick={() => navigate('/unauthorizedEmailCollection')}>
                  이메일무단수집거부
                </li>
                <li onClick={() => navigate('/privacy')}>개인정보처리방침</li>
              </ul>
            </div>
          </div>
          <p className={styles.ondocInfo}>
            *커넥트닥터는 비대면 진료서비스를 고객과 의사에게 연결해주는
            플랫폼입니다.
            <br />
            커넥트닥터는 결제와 배송 대행을 맡고 있으며 한약의 판매는 한의원에서
            이루어집니다.
          </p>
        </div>
      )}
    </>
  );
}
export default Footer;
