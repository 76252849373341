import styles from './OrderAddress.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { isDesktop } from 'react-device-detect';
import addr from '../../assets/question/addr.png';
import orderer from '../../assets/question/orderer.png';
import closeBtn from '../../assets/closeBtn03.png';
import countryDrop from '../../assets/countryDrop.png';
import mPrev from '../../assets/question/mPrev.png';
import { useEffect, useState } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ITokenInfoType, logout } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import axiosClient from '../../libs/axiosClient';

declare var daum: any;

export interface IOrdererType {
  order: {
    email: string;
    mid: string;
    name: string;
    order_title: string;
    phone: string;
    step: number;
  };
  recipient: {
    address: string;
    address_add: string;
    country_name: string;
    is_app: string;
    messanger: string;
    name: string;
    phone: string;
    post_code: string;
    request_memo: string;
  };
}
function OrderAddress() {
  const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z\s]+$/;
  const globalNameRegex = /^[a-zA-Z\s]+$/;
  const emailRegex =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
  const payappTelRegex = /^01[0-9]*$/;
  const telRegex = /^[0-9]*$/;

  const navigate = useNavigate();
  const location = useLocation();
  const isInitial = new URLSearchParams(location.search).get('isInitial');
  const { cartId } = useParams();
  const { productId } = useParams<any>();
  const { isDirect } = useParams();
  const countryList = ['미국', '중국', '일본', '캐나다', '호주', '기타'];
  const [prescriptionProgramList, setPrescriptionProgramList] = useState<any>(
    [],
  );
  const [isSameOrderer, setIsSameOrderer] = useState(false);
  const [addrInfo, setAddrInfo] = useState<any>('');
  const [ordererData, setOrdererData] = useState<IOrdererType>();
  const [countryOpen, setCountryOpen] = useState<boolean>(false);
  const [openPostcode, setOpenPostcode] = useState<boolean>(false);
  const [onlyHealthProduct, setOnlyHealthProduct] = useState(false);
  const [deliveryNation, setDeliveryNation] =
    useState('배송받을 국가를 선택해주세요');
  const [snsIdMethod, setSnsIdMethod] = useState('kakaotalk');
  const [deliverySnsId, setDeliverySnsId] = useState('');
  const [deliveryEtcNation, setDeliveryEtcNation] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const [deliveryName, setDeliveryName] = useState('');
  const [deliveryTel, setDeliveryTel] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [deliveryAddressDetail, setDeliveryAddressDetail] = useState('');
  const [globalDeliveryName, setGlobalDeliveryName] = useState('');
  const [globalDeliveryTel, setGlobalDeliveryTel] = useState('');
  const [globalZipCode, setGlobalZipCode] = useState('');
  const [globalDeliveryAddress, setGlobalDeliveryAddress] = useState('');
  const [globalDeliveryAddressDetail, setGlobalDeliveryAddressDetail] =
    useState('');
  const [deliveryMemo, setDeliveryMemo] = useState('');
  const [ordererName, setOrdererName] = useState('');
  const [ordererEmail, setOrdererEmail] = useState('');
  const [ordererTel, setOrdererTel] = useState('');
  const [validation, setValidation] = useState({
    ordererName: false,
    ordererEmail: false,
    ordererTel: false,
    deliveryNation: false,
    deliveryEtcNation: false,
    deliveryName: false,
    deliveryTel: false,
    zipCode: false,
    deliveryAddress: false,
    deliveryAddressDetail: false,
    globalDeliveryName: false,
    globalDeliveryTel: false,
    snsIdMethod: false,
    deliverySnsId: false,
    globalZipCode: false,
    globalDeliveryAddress: false,
    globalDeliveryAddressDetail: false,
  });

  useEffect(() => {
    const patientState = location.state;
    if (patientState && patientState.onlyHealth)
      setOnlyHealthProduct(patientState.onlyHealth);
    if (patientState) {
      setSnsIdMethod(patientState?.snsIdMethod);
      setDeliverySnsId(patientState?.deliverySnsId);
    }

    axiosClient
      .get('/data/combined_order/previous_order')
      .then((res) => {
        setAddrInfo(
          JSON.parse(sessionStorage.getItem('addrInfo') || JSON.stringify('')),
        );
        if (res.data.data) setOrdererData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getAddrInfo = () => {
    if (!addrInfo) return;
    setDeliveryMethod(addrInfo.deliveryMethod);
    if (addrInfo.deliveryMethod === 0) {
      setDeliveryName(addrInfo.deliveryName);
      setDeliveryTel(addrInfo.deliveryTel);
      setZipCode(addrInfo.zipCode);
      setDeliveryAddress(addrInfo.deliveryAddress);
      setDeliveryAddressDetail(addrInfo.deliveryAddressDetail);
    } else if (addrInfo.deliveryMethod === 1) {
      setDeliveryNation(addrInfo.deliveryNation);
      setDeliveryEtcNation(addrInfo.deliveryEtcNation);
      setGlobalDeliveryName(addrInfo.deliveryName);
      setGlobalDeliveryTel(addrInfo.deliveryTel);
      setGlobalZipCode(addrInfo.zipCode);
      setGlobalDeliveryAddress(addrInfo.deliveryAddress);
      setGlobalDeliveryAddressDetail(addrInfo.deliveryAddressDetail);
      setSnsIdMethod(addrInfo.snsIdMethod);
      setDeliverySnsId(addrInfo.deliverySnsId);
    }
    setOrdererName(addrInfo.ordererName);
    setOrdererEmail(addrInfo.ordererEmail);
    setOrdererTel(addrInfo.ordererTel);
    setDeliveryMemo(addrInfo.deliveryMemo);
  };

  const newOrderer = () => {
    const quesState = location.state;
    const token = localStorage.getItem('token');

    // 건강식품 주문자
    if (token) {
      if (!token || token == null || token == undefined || token == 'null')
        return;
      const userInfo: ITokenInfoType = jwt_decode(token!);
      setOrdererName(userInfo.name);
      if (nameRegex.test(userInfo.name)) setDeliveryName(userInfo.name);
      if (globalNameRegex.test(userInfo.name))
        setGlobalDeliveryName(userInfo.name);
      setOrdererTel(userInfo.phone.replace(/[-)+]/g, ''));
      setDeliveryTel(userInfo.phone.replace(/[-)+]/g, ''));
      setGlobalDeliveryTel(userInfo.phone.replace(/[-)+]/g, ''));
      setOrdererEmail(userInfo.email);
    }
    // 처방의약품 주문자
    if (quesState && quesState.patientName) {
      setOrdererName(quesState.patientName);
      setOrdererTel(quesState.patientTel);
      if (nameRegex.test(quesState.patientName))
        setDeliveryName(quesState.patientName);
      if (globalNameRegex.test(quesState.patientName))
        setGlobalDeliveryName(quesState.patientName);
      setDeliveryTel(quesState.patientTel);
      setGlobalDeliveryTel(quesState.patientTel);
    }
    getAddrInfo();
  };

  useEffect(() => {
    //신규주문자
    if (
      !ordererData ||
      Object.keys(ordererData.order).length === 0 ||
      Object.keys(ordererData.recipient).length === 0
    ) {
      return newOrderer();
    }

    // 기존주문자
    if (ordererData.order) {
      setOrdererName(ordererData.order.name);
      setOrdererTel(ordererData.order.phone);
      setOrdererEmail(ordererData.order.email);
    }
    if (ordererData.recipient) {
      if (ordererData.recipient.country_name === '한국') {
        setDeliveryMethod(0);
        setZipCode(ordererData.recipient.post_code || '');
        setDeliveryAddress(ordererData.recipient.address || '');
        setDeliveryAddressDetail(ordererData.recipient.address_add || '');
      } else {
        setDeliveryMethod(1);
        setDeliveryNation(ordererData.recipient.country_name || '');
        setGlobalZipCode(ordererData.recipient.post_code || '');
        setGlobalDeliveryAddress(ordererData.recipient.address || '');
        setGlobalDeliveryAddressDetail(ordererData.recipient.address_add || '');
        if (ordererData.recipient.messanger) {
          setSnsIdMethod(ordererData.recipient.messanger.split('__')[0]);
          setDeliverySnsId(ordererData.recipient.messanger.split('__')[1]);
        }
      }
      if (nameRegex.test(ordererData.recipient.name))
        setDeliveryName(ordererData.recipient.name || '');
      if (globalNameRegex.test(ordererData.recipient.name))
        setGlobalDeliveryName(ordererData.recipient.name || '');
      setDeliveryTel(ordererData.recipient.phone || '');
      setGlobalDeliveryTel(ordererData.recipient.phone || '');
      setDeliveryMemo(ordererData.recipient.request_memo || '');
    }
    getAddrInfo();
  }, [ordererData, addrInfo]);

  const handle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode((current) => !current);
    },

    // 주소 선택 이벤트
    selectAddress: (data: any) => {
      setZipCode(data.zonecode);
      setDeliveryAddress(data.address);
      setOpenPostcode(false);
    },
  };

  const handleSearchAddressOpen = () => {
    if (!isDesktop) setOpenPostcode(true);
    else {
      new daum.Postcode({
        oncomplete: function (data: any) {
          setZipCode(data.zonecode);
          setDeliveryAddress(data.address);
        },
      }).open({ popupKey: 'pcPostPopup1' });
    }
  };

  const saveInfo = () => {
    const infomation: any = {};
    infomation.deliveryMethod = deliveryMethod;
    infomation.deliveryName =
      deliveryMethod === 0 ? deliveryName : globalDeliveryName;
    infomation.deliveryTel =
      deliveryMethod === 0 ? deliveryTel : globalDeliveryTel;
    infomation.zipCode = deliveryMethod === 0 ? zipCode : globalZipCode;
    infomation.deliveryAddress =
      deliveryMethod === 0 ? deliveryAddress : globalDeliveryAddress;
    infomation.deliveryAddressDetail =
      deliveryMethod === 0
        ? deliveryAddressDetail
        : globalDeliveryAddressDetail;
    infomation.deliveryMemo = deliveryMemo;
    infomation.deliveryNation = deliveryMethod === 1 ? deliveryNation : '';
    infomation.deliveryEtcNation =
      deliveryNation === '기타' && deliveryEtcNation;
    infomation.snsIdMethod = deliveryMethod === 0 ? '' : snsIdMethod;
    infomation.deliverySnsId = deliverySnsId;
    infomation.ordererName = ordererName;
    infomation.ordererEmail = ordererEmail;
    infomation.ordererTel = ordererTel;
    sessionStorage.setItem('addrInfo', JSON.stringify(infomation));
  };

  const handleCheck = () => {
    let validate = {
      ordererName: false,
      ordererEmail: false,
      ordererTel: false,
      deliveryNation: false,
      deliveryEtcNation: false,
      deliveryName: false,
      deliveryTel: false,
      zipCode: false,
      deliveryAddress: false,
      deliveryAddressDetail: false,
      globalDeliveryName: false,
      globalDeliveryTel: false,
      snsIdMethod: false,
      deliverySnsId: false,
      globalZipCode: false,
      globalDeliveryAddress: false,
      globalDeliveryAddressDetail: false,
    };

    if (!nameRegex.test(ordererName)) validate.ordererName = true;
    if (!emailRegex.test(ordererEmail.trim())) validate.ordererEmail = true;
    if (!payappTelRegex.test(ordererTel) || ordererTel.length !== 11)
      validate.ordererTel = true;
    if (deliveryMethod === 0) {
      if (!zipCode.length) validate.zipCode = true;
      if (!deliveryAddress.length) validate.deliveryAddress = true;
      if (!nameRegex.test(deliveryName)) validate.deliveryName = true;
      if (!telRegex.test(deliveryTel) || deliveryTel.length < 8)
        validate.deliveryTel = true;
    }
    if (deliveryMethod === 1) {
      if (!deliveryNation.length) validate.deliveryNation = true;
      if (deliveryNation === '배송받을 국가를 선택해주세요')
        validate.deliveryNation = true;
      if (deliveryNation === '기타') {
        if (!deliveryEtcNation.length) validate.deliveryEtcNation = true;
      }
      if (!deliverySnsId.length) validate.deliverySnsId = true;
      if (!globalNameRegex.test(globalDeliveryName))
        validate.deliveryName = true;
      if (!globalDeliveryTel.length) validate.deliveryTel = true;
      if (!globalZipCode.length) validate.zipCode = true;
      if (!globalDeliveryAddress.length) validate.deliveryAddress = true;
      if (!globalDeliveryAddressDetail.length)
        validate.deliveryAddressDetail = true;
    }

    setValidation({ ...validate });

    if (Object.values(validate).includes(true)) {
      if (validate.ordererName)
        alert('주문자명은 한글, 영문, 공백만 사용 가능합니다.');
      else if (validate.ordererTel)
        alert(
          '주문자 연락처는 숫자 11자리, 01로 시작하는 형식만 사용 가능합니다.',
        );
      else alert('필수 입력 정보를 올바르게 작성해주세요.');

      return false;
    } else return true;
  };

  const onSubmit = () => {
    saveInfo();
    const isOk = handleCheck();
    if (!isOk) return;

    const patientState = location.state;
    const orderUrl = prescriptionProgramList.includes(Number(productId))
      ? `/order/${isDirect}/${productId}?${isInitial ? 'isInitial=true' : ''}`
      : `/order/${isDirect}/${cartId}?${isInitial ? 'isInitial=true' : ''}`;

    navigate(orderUrl, {
      state: {
        isReservation: isInitial ? 'Y' : 'N',
        reservationAt: patientState?.reservationAt || '',
        patientName: patientState.patientName,
        patientTel: patientState.patientTel,
        patientSecurityNumber1: patientState.patientSecurityNumber1,
        patientSecurityNumber2: isInitial
          ? '0000000'
          : patientState.patientSecurityNumber2,
        patientHeight: patientState.patientHeight,
        patientWeight: patientState.patientWeight,
        reason: patientState.reason,
        pregnancy: patientState.pregnancy,
        dailyCoffee: patientState.dailyCoffee,
        caffeineSensitivity: patientState.caffeineSensitivity,
        dietPillsExperience: patientState.dietPillsExperience,
        dietPillsLevel: patientState.dietPillsLevel,
        sideEffects: patientState.sideEffects || '',
        disease: patientState.disease,
        medicine: patientState.medicine,
        diseaseDetail: patientState.diseaseDetail || '',
        medicineDetail: patientState.medicineDetail || '',
        deliveryMethod: deliveryMethod,
        deliveryName: deliveryMethod === 0 ? deliveryName : globalDeliveryName,
        deliveryTel: deliveryMethod === 0 ? deliveryTel : globalDeliveryTel,
        zipCode: deliveryMethod === 0 ? zipCode : globalZipCode,
        deliveryAddress:
          deliveryMethod === 0 ? deliveryAddress : globalDeliveryAddress,
        deliveryAddressDetail:
          deliveryMethod === 0
            ? deliveryAddressDetail
            : globalDeliveryAddressDetail,
        deliveryMemo: deliveryMemo,
        deliveryNation:
          deliveryMethod === 0
            ? ''
            : deliveryNation !== '기타'
            ? deliveryNation
            : deliveryEtcNation,
        snsIdMethod: deliveryMethod === 0 ? '' : snsIdMethod,
        deliverySnsId: deliverySnsId,
        ordererName: ordererName,
        ordererEmail: ordererEmail,
        ordererTel: ordererTel,
      },
    });
  };

  useEffect(() => {
    if (!isSameOrderer) return;

    if (nameRegex.test(ordererName)) setDeliveryName(ordererName);
    if (globalNameRegex.test(ordererName)) setGlobalDeliveryName(ordererName);
    setDeliveryTel(ordererTel);
    setGlobalDeliveryTel(ordererTel);
  }, [isSameOrderer]);

  return (
    <div className={`${styles.container} ${!isDesktop ? styles.isMobile : ''}`}>
      {isDesktop && <Header />}
      <div className={styles.contentWrap}>
        {!isDesktop && (
          <div className={styles.prevWrap}>
            <img onClick={() => navigate(-1)} src={mPrev} alt="mPrev" />
          </div>
        )}
        <div className={styles.stepTitleWrap}>
          <span>결제하기</span>
          {onlyHealthProduct ? (
            <div className={styles.stepWrap}>
              <span>결제정보</span>
              <div className={styles.hitStep}>1</div>
              <div></div>
            </div>
          ) : (
            <div className={styles.stepWrap}>
              <span>결제정보</span>
              <div></div>
              <div className={styles.hitStep}>2</div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
        <div
          className={`${styles.mainWrap} ${!isDesktop ? styles.isMobile : ''}`}
        >
          <div className={styles.leftWrap}>
            <div
              className={`${styles.orderTitleWrap} ${styles.deliveryTitleWrap}`}
            >
              <div>
                <img src={addr} alt="addr" />
                <span>배송지 정보</span>
              </div>
              <div
                onClick={() => {
                  setIsSameOrderer((prev) => !prev);
                }}
                className={`${styles.customCheckBoxWrap} ${
                  isSameOrderer ? styles.hitCheck : ''
                }`}
              >
                <div className={styles.customCheckBox}></div>
                <span>주문자 정보와 동일</span>
              </div>
            </div>
            <div className={styles.addrForm}>
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  <span>배송법 선택</span>
                </div>
                <div className={styles.customCheckBoxListWrap}>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      deliveryMethod == 0 || deliveryMethod == -1
                        ? styles.hit
                        : ''
                    }`}
                    onClick={() => {
                      setDeliveryMethod(0);
                      validation.deliveryName = false;
                      validation.deliveryTel = false;
                      validation.zipCode = false;
                      validation.deliveryAddress = false;
                      validation.deliveryAddressDetail = false;
                    }}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>국내배송</span>
                  </div>
                  <div
                    className={`${styles.customCheckBoxWrap} ${
                      deliveryMethod == 1 ? styles.hit : ''
                    }`}
                    onClick={() => {
                      setDeliveryMethod(1);
                      validation.deliveryEtcNation = false;
                      validation.deliveryName = false;
                      validation.deliveryTel = false;
                      validation.zipCode = false;
                      validation.deliveryAddress = false;
                      validation.deliveryAddressDetail = false;
                      validation.deliveryNation = false;
                      validation.deliverySnsId = false;
                    }}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>해외배송</span>
                  </div>
                </div>
              </div>
              {deliveryMethod === 1 && (
                <div className={styles.labelWrap}>
                  <div className={styles.labelTitleWrap}>
                    <span>*</span>
                    <span>배송 국가</span>
                  </div>
                  <div className={styles.countryRightWrap}>
                    <div
                      style={{
                        border: countryOpen ? '1px solid #d9d9d9' : '',
                        borderRadius: countryOpen ? '5px 5px 0px 0px' : '',
                        borderBottom: countryOpen ? 'none' : '',
                      }}
                      className={`${styles.countrySelectWrap} ${
                        validation.deliveryNation ? styles.validationError : ''
                      }`}
                      onClick={() => {
                        setCountryOpen((prev) => !prev);
                      }}
                    >
                      <p
                        style={{
                          color:
                            deliveryNation !== '배송받을 국가를 선택해주세요'
                              ? '#131C31'
                              : '',
                        }}
                      >
                        {deliveryNation}
                      </p>
                      <img
                        src={countryDrop}
                        alt="countryDrop"
                        style={{
                          transform: countryOpen ? 'rotate(180deg)' : 'none',
                        }}
                      />
                    </div>
                    {countryOpen && (
                      <div className={styles.countryListWrap}>
                        {countryList.map((item) => (
                          <div
                            key={item}
                            onClick={() => {
                              setCountryOpen((prev) => !prev);
                              setDeliveryNation(item);
                              if (item === '기타') setDeliveryEtcNation('');
                            }}
                          >
                            <p>{item}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    {deliveryNation === '기타' && (
                      <div
                        style={{
                          marginTop: '8px',
                        }}
                      >
                        <label className={styles.label}>
                          <div
                            className={`${styles.inputWrap} ${
                              validation.deliveryEtcNation
                                ? styles.validationError
                                : ''
                            }`}
                          >
                            <input
                              type="text"
                              placeholder="ex) 대한민국"
                              className={styles.normalInput}
                              value={deliveryEtcNation}
                              onChange={(e) => {
                                setDeliveryEtcNation(e.currentTarget.value);
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  {deliveryMethod === 0 ? (
                    <span>수령인</span>
                  ) : (
                    <span>수령인(영문)</span>
                  )}
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.deliveryName ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="이름"
                    className={styles.normalInput}
                    value={
                      deliveryMethod === 0 ? deliveryName : globalDeliveryName
                    }
                    onChange={(e) => {
                      if (deliveryMethod === 0)
                        setDeliveryName(e.currentTarget.value);
                      else setGlobalDeliveryName(e.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  <span>연락처</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.deliveryTel ? styles.validationError : ''
                  }`}
                >
                  {deliveryMethod === 0 ? (
                    <input
                      type="text"
                      placeholder="01000000000"
                      className={styles.normalInput}
                      value={deliveryTel}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        // if (isNaN(Number(value)) && value !== '+') return;
                        setDeliveryTel(value);
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="연락처를 입력해주세요"
                      className={styles.normalInput}
                      value={globalDeliveryTel}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        setGlobalDeliveryTel(value);
                      }}
                    />
                  )}
                </div>
              </div>
              {deliveryMethod === 1 && (
                <div className={styles.labelWrap}>
                  <div className={styles.labelTitleWrap}>
                    <span>*</span>
                    <span>아이디</span>
                  </div>
                  <div className={styles.customCheckBoxListWrap}>
                    <div
                      className={`${styles.customCheckBoxWrap} ${
                        snsIdMethod === 'kakaotalk' ? styles.hit : ''
                      }`}
                      onClick={() => {
                        setSnsIdMethod('kakaotalk');
                        if (snsIdMethod !== 'kakaotalk') setDeliverySnsId('');
                      }}
                    >
                      <div className={styles.customCheckBox}></div>
                      <span>KakaoTalk ID</span>
                    </div>
                    <div
                      className={`${styles.customCheckBoxWrap} ${
                        snsIdMethod === 'line' ? styles.hit : ''
                      }`}
                      onClick={() => {
                        setSnsIdMethod('line');
                        if (snsIdMethod !== 'line') setDeliverySnsId('');
                      }}
                    >
                      <div className={styles.customCheckBox}></div>
                      <span>Line ID</span>
                    </div>
                  </div>
                </div>
              )}
              {deliveryMethod === 1 && (
                <label
                  style={{
                    marginTop: '-8px',
                  }}
                  className={styles.labelWrap}
                >
                  <div
                    className={`${styles.inputWrap} ${
                      validation.deliverySnsId ? styles.validationError : ''
                    }`}
                  >
                    <input
                      type="text"
                      placeholder="카카오톡 또는 라인아이디"
                      className={styles.normalInput}
                      value={deliverySnsId}
                      onChange={(e) => setDeliverySnsId(e.currentTarget.value)}
                    />
                  </div>
                  <p className={styles.globalTelAlert}>
                    * 해외 체류로 인해 카카오톡/라인 ID를 입력하신 경우,
                    보이스톡으로 진료를 진행합니다. {isDesktop && <br />}반드시{' '}
                    <span className={styles.hit}>아이디 검색 허용</span>을 미리
                    설정해 주세요.
                  </p>
                </label>
              )}
              <div className={`${styles.labelWrap} ${styles.addrLabelWrap}`}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  {deliveryMethod === 0 ? (
                    <span>배송지 주소</span>
                  ) : (
                    <span>배송지 주소(영문)</span>
                  )}
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.zipCode ? styles.validationError : ''
                  }`}
                >
                  {deliveryMethod === 0 ? (
                    <input
                      style={{
                        borderRadius: '5px 0 0 5px',
                      }}
                      type="text"
                      placeholder="우편번호"
                      value={zipCode}
                      readOnly
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="우편번호"
                      value={globalZipCode}
                      onChange={(e) => setGlobalZipCode(e.currentTarget.value)}
                    />
                  )}
                  {deliveryMethod === 0 && (
                    <div
                      onClick={handleSearchAddressOpen}
                      className={styles.addressSearch}
                    >
                      검색
                    </div>
                  )}
                  {openPostcode && (
                    <div className={styles.addressModalWrap}>
                      <div className={styles.addressModal}>
                        <div className={styles.modalHeader}>
                          <img
                            src={closeBtn}
                            alt="closeBtn"
                            onClick={() => setOpenPostcode(false)}
                          />
                        </div>
                        <DaumPostcode
                          onComplete={handle.selectAddress}
                          autoClose={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {deliveryMethod === 1 && (
                  <div className={styles.addrText}>ex) 100102</div>
                )}
                <div
                  className={`${styles.inputWrap} ${
                    validation.deliveryAddress ? styles.validationError : ''
                  }`}
                >
                  {deliveryMethod === 0 ? (
                    <input
                      type="text"
                      placeholder="주소"
                      className={styles.normalInput}
                      value={deliveryAddress}
                      readOnly
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="주소"
                      className={styles.normalInput}
                      value={globalDeliveryAddress}
                      onChange={(e) =>
                        setGlobalDeliveryAddress(e.currentTarget.value)
                      }
                    />
                  )}
                </div>
                {deliveryMethod === 1 && (
                  <div className={styles.addrText}>ex) 123 Main Street</div>
                )}
                <div
                  className={`${styles.inputWrap} ${
                    validation.deliveryAddressDetail
                      ? styles.validationError
                      : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="상세주소"
                    className={styles.normalInput}
                    value={
                      deliveryMethod === 0
                        ? deliveryAddressDetail
                        : globalDeliveryAddressDetail
                    }
                    onChange={(e) => {
                      if (deliveryMethod === 0)
                        setDeliveryAddressDetail(e.currentTarget.value);
                      else
                        setGlobalDeliveryAddressDetail(e.currentTarget.value);
                    }}
                  />
                </div>
                {deliveryMethod === 1 && (
                  <div className={`${styles.addrText} ${styles.lastAddrText}`}>
                    ex) Anytown, CA 12345, USA
                  </div>
                )}
              </div>
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span></span>
                  <span>배송메세지</span>
                </div>
                <div className={styles.inputWrap}>
                  <input
                    type="text"
                    placeholder="메시지를 입력해주세요"
                    className={styles.normalInput}
                    value={deliveryMemo}
                    onChange={(e) => setDeliveryMemo(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightWrap}>
            <div className={styles.orderTitleWrap}>
              <img src={orderer} alt="orderer" />
              <span>주문자 정보</span>
            </div>
            <div className={styles.addrForm}>
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  <span>주문자명</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.ordererName ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="이름"
                    className={styles.normalInput}
                    value={ordererName}
                    onChange={(e) => setOrdererName(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  <span>이메일 주소</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.ordererEmail ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="ondoc@naver.com"
                    className={styles.normalInput}
                    value={ordererEmail}
                    onChange={(e) => setOrdererEmail(e.currentTarget.value)}
                    onBlur={() => setOrdererEmail((prev) => prev.trim())}
                  />
                </div>
              </div>
              <div className={styles.labelWrap}>
                <div className={styles.labelTitleWrap}>
                  <span>*</span>
                  <span>연락처</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.ordererTel ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    placeholder="01000000000"
                    className={styles.normalInput}
                    value={ordererTel}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setOrdererTel(value);
                    }}
                  />
                </div>
              </div>
            </div>
            {isDesktop && (
              <div className={styles.orderBtnWrap} onClick={onSubmit}>
                다음
              </div>
            )}
          </div>
        </div>
        {!isDesktop && (
          <div
            className={`${styles.btnWrap} ${!isDesktop ? styles.isMobile : ''}`}
          >
            <div className={styles.orderBtnWrap} onClick={onSubmit}>
              다음
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderAddress;
